import { Controller } from '@hotwired/stimulus'
import { swing, inflate, smallJump, bigJump, pirouette } from '../utils/anime'
import { useThrottle } from 'stimulus-use'

export default class extends Controller {
  static targets = ['character']
  static throttles = ['poussin']

  initialize() {
    useThrottle(this, { wait: 600 })
  }

  connect() {
    if (!this.hasCharacterTarget) return

    this.anime = {
      swing: swing(this.characterTarget),
      inflate: inflate(this.characterTarget),
      smallJump: smallJump(this.characterTarget),
      bigJump: bigJump(this.characterTarget),
      pirouette: pirouette(this.characterTarget)
    }

    this.jumpOnAppear()
  }

  poussin(e) {
    switch (e.key) {
      case 'Enter':
        this.anime?.pirouette.restart()
        break
      case 'à':
      case 'â':
      case 'ä':
      case 'é':
      case 'è':
      case 'ê':
      case 'ë':
      case 'ï':
      case 'î':
      case 'ô':
      case 'ö':
      case 'ù':
      case 'û':
      case 'ü':
      case 'ÿ':
      case 'ç':
        this.anime?.bigJump.restart()
        break
      case '.':
      case ',':
      case ':':
      case '-':
      case '"':
        this.anime?.smallJump.restart()
        break
      default:
        if (e.type == 'click') {
          this.anime?.smallJump.restart()
        }
        break
    }
  }

  jumpOnAppear() {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.target.nodeName === 'SECTION') {
          setTimeout(() => {
            this.anime.smallJump.restart()
          }, 500)
        }
      })
    })

    if (this.element.closest('section')) {
      observer.observe(this.element.closest('section'), {
        attributes: true,
        attributeFilter: ['class']
      })
    }
  }
}
