import { ApplicationController, useThrottle, useDebounce } from 'stimulus-use'
import { patch } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['range', 'button']
  static values = { updateUrl: String }
  static classes = ['selectedButton']
  static throttles = ['_updateTextSizeAfterDebounce']
  static debounces = ['_updateTextSizeAfterDebounce']

  connect() {
    this.element.controller = this
    useThrottle(this, { wait: 500 })
    useDebounce(this, { wait: 500 })

    this._updateProgressBar()
  }

  disconnect() {}

  open(buttonController) {
    this.buttonController = buttonController
    this.element.classList.remove('hidden')
  }

  close() {
    this.element.classList.add('hidden')
    this.buttonController?.unselect()
  }

  updateTextSize() {
    this._updateProgressBar()
    this._updateTextSizeAfterDebounce()
  }

  selectFont(event) {
    this.dyslexicValue = event.params.dyslexic

    this._updateSettings()
    this._updateFont()

    this._unstyleButtons()
    event.currentTarget.classList.add(...this.selectedButtonClasses)
  }

  // Webkit does not support custom style for Range Progress
  // Let's cheat Lomig style
  _updateProgressBar() {
    const rangeTarget = this.rangeTarget
    const progress =
      ((rangeTarget.value - rangeTarget.min) / (rangeTarget.max - rangeTarget.min)) * 100

    rangeTarget.style.setProperty('--range-progress', `${progress}%`)
  }

  _updateTextSizeAfterDebounce() {
    const newValue = parseFloat(this.rangeTarget.value) * 16
    document.documentElement.style.setProperty('--font-size', `${newValue}px`)

    this._updateSettings()
  }

  async _updateSettings() {
    const body = {
      dyslexic: this.dyslexicValue,
      font_size: this.rangeTarget.value
    }

    await patch(this.updateUrlValue, { body: body, responseKind: 'js' })
  }

  _updateFont() {
    if (this.dyslexicValue) {
      document.documentElement.style.setProperty('--main-font', 'var(--opendys-font)')
      document.documentElement.style.setProperty('--secondary-font', 'var(--opendys-font)')
    } else {
      document.documentElement.style.setProperty('--main-font', 'var(--poppins-font)')
      document.documentElement.style.setProperty('--secondary-font', 'var(--alice-font)')
    }
  }

  _unstyleButtons() {
    this.buttonTargets.forEach(button => button.classList.remove(...this.selectedButtonClasses))
  }
}
