import { Controller } from '@hotwired/stimulus'
import morphdom from 'morphdom'

export default class extends Controller {
  static targets = ['view']

  morphReplace(e) {
    const [_data, _status, xhr] = e.detail
    if (xhr.response) {
      const template = document.createElement('template')
      template.innerHTML = xhr.response
      const activeElement = document.activeElement

      morphdom(this.element, xhr.response, {
        onBeforeElUpdated: function (fromEl, toEl) {
          if (fromEl.isEqualNode(toEl)) {
            return false
          }
          return verifyNotPermanent(fromEl, toEl)
        },
        childrenOnly: false
      })
      requestAnimationFrame(() => {
        activeElement.focus() // doesn't seesm to work on contact fields
      })
    }
  }

  replace(e) {
    const [_data, _status, xhr] = e.detail
    this.targetElement.outerHTML = xhr.response
  }

  replaceInner(e) {
    const [_data, _status, xhr] = e.detail
    this.targetElement.innerHTML = xhr.response
  }

  get targetElement() {
    return this.hasViewTarget ? this.viewTarget : this.element
  }
}

export const verifyNotPermanent = (fromEl, toEl) => {
  const permanent = fromEl.closest(`[data-permanent]`)

  return !permanent
}
