import { Controller } from '@hotwired/stimulus'
import { useHover } from 'stimulus-use'

// adds a bit of JS for the Plus checkbox to maintain the hover state just after the checkbox is checked
// and does not display the hover state yet.
export default class extends Controller {
  connect() {
    useHover(this)
    this.element.addEventListener('change', this.handleChange.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('change', this.handleChange.bind(this))
  }

  // callbacks

  handleChange(event) {
    this.element.classList.add('has-been-checked')
  }

  mouseLeave(event) {
    this.element.classList.remove('has-been-checked')
  }
}
