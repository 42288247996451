// others
import 'mutationobserver-shim'
import 'mutation-observer-inner-html-shim'
import 'element-closest'
import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill'
import 'intersection-observer'
import 'weakmap-polyfill'
import 'form-request-submit-polyfill'
import '@webcomponents/custom-elements'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

// ResizeObserver Polyfill
import ResizeObserverPolyfill from 'resize-observer-polyfill'
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill
}

// for old android phones
;(function () {
  if (!Array.prototype.forEach) {
    Array.prototype.forEach = function forEach(callback, thisArg) {
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function')
      }
      var array = this
      thisArg = thisArg || this
      for (var i = 0, l = array.length; i !== l; ++i) {
        callback.call(thisArg, array[i], i, array)
      }
    }
  }
})()

// array.includes()
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict'

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp')
    }
    if (start === undefined) {
      start = 0
    }
    return this.indexOf(search, start) !== -1
  }
}
