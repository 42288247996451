import { Controller } from '@hotwired/stimulus'
import pluralize from 'pluralize'

export default class extends Controller {
  static targets = ['cartUrl', 'cartQuantity']

  buildUrl(event) {
    const kidStoryId = event.currentTarget.dataset.kidStoryId
    const userId = event.currentTarget.dataset.userId
    if (kidStoryId && event.target.checked) {
      this.addSelectedToButton(event)
      const order = {
        [kidStoryId]: {
          userId,
          kidStoryId
        }
      }
      this.addOrder(order)
    } else {
      this.removeSelectedFromButton(event)
      this.removeOrder(kidStoryId)
    }
  }

  addSelectedToButton(event) {
    event.target.labels[0].innerText = 'Histoire sélectionnée'
    event.target.labels[0].classList.remove('btn-primary')
    event.target.labels[0].classList.add('btn-warning')
  }

  removeSelectedFromButton(event) {
    event.target.labels[0].innerText = 'Sélectionner cette histoire'
    event.target.labels[0].classList.remove('btn-warning')
    event.target.labels[0].classList.add('btn-primary')
  }

  addOrder(order) {
    this.orders = { ...this.orders, ...order }
  }

  removeOrder(key) {
    const orders = this.orders
    delete orders[key]
    this.orders = orders
  }

  set orders(orders) {
    this.data.set('orders', JSON.stringify(orders))
    this.updateCart()
  }

  get orders() {
    return JSON.parse(this.data.get('orders'))
  }

  updateCart() {
    const notes = Object.keys(this.orders).reduce(
      (array, storyKidIds) => [
        ...array,
        {
          user_id: this.orders[storyKidIds].userId,
          kid_story_id: this.orders[storyKidIds].kidStoryId
        }
      ],
      []
    )

    const quantity = notes.length
    if (quantity != 0) {
      this.cartUrlTarget.innerHTML = `
        <a class="btn btn-warning" target="_blank" rel="noopener noreferrer"
          href="https://la-boutique-de-plume.myshopify.com/cart/20104386019441:${quantity}?channel=buy_button&note=${encodeURIComponent(
        JSON.stringify(notes)
      )}">
          Commander maintenant
        </a>
      `

      this.cartQuantityTarget.innerHTML = `${quantity} ${pluralize('histoire', quantity)} (${
        quantity * 19.9
      } €)`
    } else {
      this.cartUrlTarget.innerHTML = ''
      this.cartQuantityTarget.innerHTML = quantity
    }
    return notes
  }
}
