import { Controller } from '@hotwired/stimulus'

// algolia
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import algoliasearch from 'algoliasearch'

// data adapter
import SchoolDataAdapter from '../models/school_data_adapter'

export default class extends Controller {
  static targets = [
    'value',
    'form',
    'submit',
    'zipInput',
    'cityInput',
    'streetInput',
    'districtInput',
    'stateInput',
    'countryUniqIdInput',
    'uaiInput',
    'academyInput',
    'countryInput',
    'phoneInput',
    'gradeInput',
    'populationInput',
    'name',
    'contractType',
    'tooltip',
    'searchable',
    'unfindable'
  ]

  static values = {
    locale: String,
    placeholder: String
  }

  // lifecycle

  connect() {
    this.#startAutocomplete()
  }

  // actions

  displayEmptyForm() {
    if (!this.hasUnfindableTarget) return

    this.unfindableTarget.classList.add('hidden')
    this.formTarget.classList.remove('hidden', 'disabled')
    this.formTarget.querySelectorAll("input:not([type='radio']), select").forEach(element => {
      element.value = null
    })
    document.querySelector('.aa-Input').value = null
    this.countryUniqIdInputTarget.value = null
    this.uaiInputTarget.value = null
  }

  // private

  #startAutocomplete() {
    const debounced = this.#debouncePromise(items => Promise.resolve(items), 300)

    autocomplete({
      container: '#autocomplete',
      placeholder: this.placeholderValue,
      autoFocus: true,
      insights: true,
      getSources: params => debounced(this.getSources(params)),
      onReset: () => this.displayEmptyForm()
    })
  }

  getSources({ query }) {
    return [
      {
        sourceId: 'schools',
        getItems: () => this.getItems(query),
        getItemInputValue: ({ item }) => {
          return `${item.nameTarget} - ${item.cityInputTarget}` // The attribute you want to display when an item is selected
        },
        templates: {
          item: this.renderItem
        },
        onSelect: ({ item, components }) => {
          this.displayEmptyForm()
          this.formTarget.classList.add('disabled')
          this.#addValuesToInputs(item)
          this.submitTarget.disabled = false
        }
      }
    ]
  }

  getItems = query => {
    return getAlgoliaResults({
      searchClient: this.searchClient,
      queries: [
        {
          indexName: this.indexName,
          query,
          params: {
            hitsPerPage: 50,
            snippetEllipsisText: '…'
          }
        }
      ],
      transformResponse: ({ hits }) => {
        const newHits = hits[0].map(hit => {
          const adapter = new SchoolDataAdapter(hit, this.localeValue)
          return adapter.formData()
        })
        return [newHits]
      }
    })
  }

  renderItem = ({ item, html }) => {
    return html`
      <div class="flex flex-col">
        <div class="title--md">${item.nameTarget}</div>
        <div class="body--md">${item.zipInputTarget} ${item.cityInputTarget}</div>
      </div>
    `
  }

  #addValuesToInputs = item => {
    if (this.hasNameTarget) {
      this.nameTarget.value = item.nameTarget
    }
    if (this.hasZipInputTarget) {
      this.zipInputTarget.value = item.zipInputTarget
    }
    if (this.hasCityInputTarget) {
      this.cityInputTarget.value = item.cityInputTarget
    }
    if (this.hasStreetInputTarget) {
      this.streetInputTarget.value = item.streetInputTarget
    }
    if (this.hasCountryInputTarget) {
      this.countryInputTarget.value = item.countryInputTarget
    }
    if (this.hasAcademyInputTarget) {
      this.academyInputTarget.value = item.academyInputTarget
    }
    if (this.hasDistrictInputTarget) {
      this.districtInputTarget.value = item.districtInputTarget
    }
    if (this.hasStateInputTarget) {
      this.stateInputTarget.value = item.stateInputTarget
    }
    if (this.hasGradeInputTarget) {
      this.gradeInputTarget.value = item.gradeInputTarget
    }
    if (this.hasPopulationInputTarget) {
      this.populationInputTarget.value = item.populationInputTarget
    }
    if (this.hasCountryUniqIdInputTarget) {
      this.countryUniqIdInputTarget.value = item.countryUniqIdInputTarget
    }
    if (this.hasUaiInputTarget) {
      this.uaiInputTarget.value = item.countryUniqIdInputTarget
    }
    if (this.hasPhoneInputTarget) {
      this.phoneInputTarget.value = item.phoneInputTarget
    }
    if (this.hasContractTypeTarget) {
      this.contractTypeTargets.forEach(radio => {
        if (radio.value === item.contractTypeTarget) {
          radio.checked = true
        }
      })
    }
  }

  #debouncePromise(fn, time) {
    let timerId = undefined

    return function debounced(...args) {
      if (timerId) {
        clearTimeout(timerId)
      }

      return new Promise(resolve => {
        timerId = setTimeout(() => resolve(fn(...args)), time)
      })
    }
  }

  // getters

  get searchClient() {
    return algoliasearch('YFBQM78GPE', '5faab170bfdfd4183a381e636daf737d')
  }

  get indexName() {
    return `${this.localeValue}_schools`
  }
}
