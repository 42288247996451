import { Controller } from '@hotwired/stimulus'
import SweetScroll from 'sweet-scroll'

export default class extends Controller {
  static targets = ['writing', 'editor', 'chapterContainer', 'textarea']

  scrollAndEdit() {
    this.initScroller()
    this.scroller.toElement(this.editorTarget)
  }

  initScroller() {
    this.scroller = new SweetScroll({
      duration: 1500,
      offset: 150,
      header: '.navbar',
      easing: 'easeOutQuint'
    })
  }
}
