import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'
import Sortable from 'sortablejs'

/*
 * Usage
 * =====
 *
 * add data-controller="sortable" to common ancestor
 *
 * 1. Add data-sortable-url-value="URL to update position" to the
 * controller container
 *
 * 2. Add data-sortable-handle to the drag handle:
 *
 * 3. Add data-sortable-id to the item
 *
 */
export default class extends Controller {
  static values = { url: String }
  static targets = ['item']

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      animation: 150,
      ghostClass: 'opacity-0',
      chosenClass: '!bg-gray-lightest',
      handle: '[data-sortable-handle]'
    })
  }

  disconnect() {
    this.sortable.destroy()
  }

  onEnd(event) {
    const { newIndex, item } = event
    const id = item.dataset.sortableId
    const url = this.urlValue.replace(':id', id)
    put(url, {
      body: { position: newIndex + 1 }
    })
  }
}
