import Controller from './game_controller'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static values = { correctWords: Array }

  initialize() {
    // we must define it again otherwise it is overwriten by Stimulus default Dispacth function
    useDispatch(this, { eventPrefix: false })
  }

  // actions

  validate = e => {
    e.preventDefault()
    e.stopPropagation()
    this.checkedTargets.forEach(wordTarget => {
      const isValidWord = wordTarget.dataset.isValidWord === 'true'
      wordTarget.classList.toggle('success', isValidWord)
      wordTarget.classList.toggle('error', !isValidWord)
      wordTarget.classList.toggle('describe', !isValidWord)
    })
    if (this.isAnswerCorrect) {
      this.success()
    } else {
      this.error()
      this.removeErrorSuccessHighlight(1000)
    }
  }

  removeErrorSuccessHighlight(delay = 1000) {
    setTimeout(() => {
      this.wordTargets.forEach(wordTarget => {
        wordTarget.classList.remove('success')
        this.checkedTargets.forEach(checkbox => (checkbox.checked = false))
      })
    }, delay)
  }

  // getters
  get checkedTargets() {
    return this.wordTargets.filter(checkbox => checkbox.checked)
  }

  get isAnswerCorrect() {
    return JSON.stringify(this.correctWordsValue.sort()) === JSON.stringify(this.selectedWord)
  }

  get selectedWord() {
    return this.checkedTargets.map(checkbox => checkbox.value).sort()
  }
}
