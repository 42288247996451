import { Controller } from '@hotwired/stimulus'
import { TemplateInstance } from '@github/template-parts'

export default class extends Controller {
  static targets = ['row', 'rows', 'template']
  static values = { maxRows: Number, minRows: Number }

  connect() {
    if (this.emptyRows.length === 0) {
      this.performAddRow()
    }
  }

  addRow(e) {
    const target = e.currentTarget
    const last = target === this.lastRowTarget
    if (last) {
      this.performAddRow()
    }
  }

  removeRow(e) {
    const row = e.currentTarget.closest('li')
    row.remove()
  }

  // private

  performAddRow() {
    if (this.emptyRowsCount <= 1 && this.rowsCount < this.maxRowsValue) {
      const rowFromTemplate = new TemplateInstance(this.templateTarget)
      this.rowsTarget.append(rowFromTemplate)
    }
  }

  get lastRowTarget() {
    return this.rowTargets.slice(-1)[0]
  }

  get rowsCount() {
    return this.rowTargets.length
  }

  get emptyRowsCount() {
    return this.emptyRows.length
  }

  get emptyRows() {
    return this.rowTargets
      .filter(row => {
        // check that all inputs are empty
        return Array.from(row.querySelectorAll('input'))
          .map(input => input.value === '')
          .every(input => input)
      })
      .map(row => row.closest('li'))
  }
}
