import Controller from './application_controller'
import { useResize } from 'stimulus-use'

const root = document.documentElement

export default class extends Controller {
  static targets = ['rows', 'card', 'seeMoreButton']

  connect() {
    useResize(this)
    this.rowsTarget.style.overflow = 'visible'
  }

  resize() {
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    requestAnimationFrame(() => {
      this.seeMoreButtonTarget.style.display = this.shouldDisplaySeeMore ? 'block' : 'none'
      if (!this.rowsTarget.classList.contains('cards-row__cards--expanded')) {
        this.cardTargets.forEach((card, index) => {
          if (index >= Math.trunc(this.cardPerRow)) {
            card.classList.add('welcome-space-card--hidden')
          } else {
            card.classList.remove('welcome-space-card--hidden')
          }
        })
      }
    })
  }

  toggleRow() {
    this.rowsTarget.classList.toggle('cards-row__cards--expanded')
    this.cardTargets.forEach(
      (card, i) =>
        i >= Math.trunc(this.cardPerRow) && card.classList.toggle('welcome-space-card--hidden')
    )
    this.seeMoreButtonTarget.innerHTML = this.rowsTarget.classList.contains(
      'cards-row__cards--expanded'
    )
      ? 'Voir moins'
      : 'Voir plus'
  }

  get shouldDisplaySeeMore() {
    return this.hasCardTarget ? this.cardTargets.length > this.cardPerRow : false
  }

  get cardPerRow() {
    if (this.hasCardTarget) {
      return (
        this.rowsTarget.getBoundingClientRect().width /
        this.cardTarget.getBoundingClientRect().width
      )
    } else {
      return 1
    }
  }

  get rowHeight() {
    if (this.hasCardTarget) {
      return `${this.cardTarget.getBoundingClientRect().height}px`
    } else {
      return '20px'
    }
  }
}
