import Controller from './application_controller'

export default class extends Controller {
  static values = {
    wait: { type: Number, default: 0 },
    path: { type: String, default: '/users/sign_out' },
    redirectTo: { type: String, default: '/gar_logout' }
  }

  connect() {
    if (!this.isPreview) {
      this.timeout = setTimeout(() => {
        // initially I wanted to submit the form to disconnect
        // for whatever reason this is working perfectly in dev/test
        // but throw a cors error in the console in production
        //
        // this.signOutForm.requestSubmit()

        // poor man solution we redirect to the page as session_index was cleared
        // the user is disconnected
        Turbo.visit(this.redirectToValue, { action: 'replace' })
        // window.location = this.redirectToValue
      }, this.waitValue)
    }
  }

  disconnect() {
    // because the notification are Streamed to a notification stack and then popped one by one the
    // Stimulus controller might kick in while waiting in that stack therefore we clear it to avoid
    // clicking twice the logout link. Clicking twice created some disconection failures
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  get signOutForm() {
    return document.querySelector(`[action*="${this.pathValue}"]`)
  }
}
