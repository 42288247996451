import { Mark, mergeAttributes } from '@tiptap/core'
import { diffWords } from 'diff'
import { AddMarkStep, RemoveMarkStep } from 'prosemirror-transform'

export function htmlToText(html) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  function recursiveText(node, isRoot = true) {
    let text = ''
    node.childNodes.forEach((child, index) => {
      if (child.nodeType === 3) {
        // Text node
        text += child.nodeValue
      } else if (child.nodeType === 1) {
        // Element node
        if (child.tagName.toLowerCase() === 'br') {
          text += '\n'
        } else if (child.tagName.toLowerCase() === 'p') {
          if (!isRoot && text && text[text.length - 1] !== '\n') text += '\n' // Add newline before if not first element and not after a newline
          text += recursiveText(child, false)
          text += '\n\n' // Add double newlines after paragraphs
        } else {
          text += recursiveText(child, false)
        }
      }
    })
    return text
  }

  return recursiveText(doc.body).trim() // Trim to remove any leading/trailing newlines
}

export function createDiffMarks(initialText, newText) {
  let index = 0
  const diff = diffWords(initialText, newText)
  const diffMarks = []

  diff.forEach(({ removed, count, value, added }) => {
    if (removed) {
      // we don't mark the removed texts
      return
    }

    if (!added) {
      // This is the case when the text is the same, we just update the index and continue
      index += value.length
      return
    }

    // Only handle added cases
    let from = index + 1 // Adjust index based on your editor's requirements
    let to = index + value.length + 1
    index += value.length

    // if value is a set of new lines or white spaces, we don't mark them
    if (value.match(/^\s+$/)) {
      return
    }

    diffMarks.push({ from, to, value })
  })

  return diffMarks
}

const DiffExtension = Mark.create({
  name: 'diffWord',
  addOptions() {
    return {
      HTMLAttributes: {
        'data-diff': 'word'
      },
      initialText: ''
    }
  },

  parseHTML() {
    return [
      {
        tag: 'mark',
        getAttrs: node => {
          return node.getAttribute('data-diff') === 'word' ? {} : false
        }
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addCommands() {
    return {
      setDiff:
        attributes =>
        ({ commands, editor }) => {
          if (editor.getAttributes('highlight').length) return

          return commands.setMark(this.name, attributes)
        },
      unsetDiff:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        }
    }
  },
  onCreate({ editor }) {
    // if we don't have an initial text to compare, we don't do anything
    if (this.options.initialText.length === 0) return

    const diffMarks = createDiffMarks(this.options.initialText, htmlToText(this.editor.getHTML()))

    const currentAnchor = this.editor.state.selection.anchor

    const chain = this.editor.chain().focus().setMeta('ignore', true)

    // remove all diff marks
    chain.selectAll().unsetDiff()

    // reapply the new ones
    diffMarks.forEach(({ from, to }) => {
      chain.setTextSelection({ from, to }).setDiff()
    })
    chain.setTextSelection(currentAnchor)
    chain.run()
  },
  onUpdate({ transaction, editor }) {
    if (transaction.getMeta('ignore')) {
      return // Ignore transactions explicitly marked to be ignored
    }

    const isMarkTransaction = transaction.steps.every(
      step => step instanceof AddMarkStep || step instanceof RemoveMarkStep
    )

    if (isMarkTransaction) {
      // Transaction only contains AddMarkStep, ignoring
      return
    }

    // if we don't have an initial text to compare, we don't do anything
    if (this.options.initialText.length === 0) return

    const diffMarks = createDiffMarks(this.options.initialText, htmlToText(this.editor.getHTML()))

    const currentAnchor = this.editor.state.selection.anchor

    const chain = this.editor.chain().focus().setMeta('ignore', true)

    // remove all diff marks
    chain.selectAll().unsetDiff()

    // reapply the new ones
    diffMarks.forEach(({ from, to }) => {
      chain.setTextSelection({ from, to }).setDiff()
    })
    chain.setTextSelection(currentAnchor)
    chain.run()
  }
})

export default DiffExtension
