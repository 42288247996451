import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['answer']

  submit(e) {
    this.answerTarget.value = e.currentTarget.dataset.answer
    this.element.submit()
  }
}
