import Controller from './application_controller'

export default class extends Controller {
  static targets = ['input']
  static values = { placeholder: String, placeholderText: String }

  connect() {
    import(/* webpackChunkName: "tagify" */ '@yaireo/tagify')
      .then(Module => {
        this.Tagify = Module.default
        this.initializeTagify()
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  // private

  displayError = () => {
    requestAnimationFrame(() => {
      this.tagify.DOM.input.setAttribute('data-placeholder', 'adresse email invalide')
    })
  }

  initializeTagify() {
    this.tagify = new this.Tagify(this.element, {
      keepInvalidTags: true,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      editTags: {
        keepInvalid: false
      }
    })

    this.updatePlaceholder()
    this.tagify.on('add', this.updatePlaceholder)
    this.tagify.on('remove', this.updatePlaceholder)
    this.tagify.on('invalid', this.displayError)
  }

  updatePlaceholder = () => {
    requestAnimationFrame(() => {
      this.tagify.DOM.input.setAttribute('data-placeholder', this.placeholder)
    })
  }

  get placeholder() {
    return this.element.value ? '' : this.element.placeholder || this.placeholderTextValue
  }
}
