import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'submitButton',
    'checkbox',
    'searchbox',
    'classSelect',
    'studentItem',
    'studentsContainer',
    'emptyState'
  ]

  static values = { groupParticipation: Boolean }

  connect() {
    this.updateSubmitButton()
    this.sortStudents()
  }

  updateSubmitButton() {
    const anySelected = this.checkboxTargets.some(checkbox => checkbox.checked)
    this.submitButtonTarget.disabled = !anySelected
  }

  search() {
    const query = this.searchboxTarget.value.toLowerCase()
    const selectedClassId = this.classSelectTarget.value

    this.studentItemTargets.forEach(studentItem => {
      const kidName = studentItem.dataset.kidName.toLowerCase()
      const classMatch =
        selectedClassId === '' || studentItem.dataset.classroomId === selectedClassId

      studentItem.classList.toggle('hidden', !(kidName.includes(query) && classMatch))
    })

    this.updateNoResults()
    if (this.groupParticipationValue) {
      this.sortStudents()
    }
  }

  filterByClass() {
    const selectedClassId = this.classSelectTarget.value

    this.studentItemTargets.forEach(studentItem => {
      const classMatch =
        selectedClassId === '' || studentItem.dataset.classroomId === selectedClassId
      studentItem.classList.toggle('hidden', !classMatch)
    })

    this.searchboxTarget.value = ''
    this.updateNoResults()
    if (this.groupParticipationValue) {
      this.sortStudents()
    }
  }

  updateNoResults() {
    const anyVisible = this.studentItemTargets.some(
      student => !student.classList.contains('hidden')
    )
    this.emptyStateTarget.classList.toggle('hidden', anyVisible)
  }

  sortStudents() {
    if (!this.groupParticipationValue) return

    const container = this.studentsContainerTarget
    const items = Array.from(this.studentItemTargets)

    items.sort((a, b) => {
      const aChecked = a.querySelector('input[type="checkbox"]').checked
      const bChecked = b.querySelector('input[type="checkbox"]').checked
      const aHidden = a.classList.contains('hidden')
      const bHidden = b.classList.contains('hidden')

      // First prioritize checked and visible elements
      if (aChecked && !aHidden && (!bChecked || bHidden)) return -1
      if (bChecked && !bHidden && (!aChecked || aHidden)) return 1

      // Then visible elements
      if (!aHidden && bHidden) return -1
      if (aHidden && !bHidden) return 1

      // Then order by classroom id
      return a.dataset.classroomId.localeCompare(b.dataset.classroomId)
    })

    items.forEach(item => container.appendChild(item))
  }
}
