import tippy, { roundArrow } from 'tippy.js'

tippy.setDefaultProps({
  animation: 'scale-subtle',
  inertia: true,
  arrow: roundArrow,
  theme: 'plume',
  delay: [100, 200],
  duration: [400, 250],
  maxWidth: 'none',
  interactive: true,
  placement: 'top-end',
  popperOptions: {
    strategy: 'fixed'
  }
})

if (typeof Turbo !== 'undefined') {
  document.addEventListener('turbo:load', function () {
    tippy('[data-tippy-content]')
  })
} else {
  tippy('[data-tippy-content]')
}
