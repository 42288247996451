import Controller from './application_controller'

const style = {
  base: {
    lineHeight: '1.429',
    fontSize: '19.2px'
  }
}

export default class extends Controller {
  static targets = [
    'cardNumber',
    'cardExpiry',
    'cardCvc',
    'cardElement',
    'cardError',
    'form',
    'tokenInput',
    'paymentMethodInput',
    'firstName',
    'lastName',
    'email',
    'submit',
    'pay'
  ]

  cardComplete = {
    number: false,
    expiry: false,
    cvc: false
  }

  connect() {
    import(/* webpackChunkName: "stripe-js" */ '@stripe/stripe-js')
      .then(Module => {
        this.loadStripe = Module.loadStripe
        this.initializeForm()
      })
      .catch(this.handleError)
  }

  async initializeForm() {
    this.stripe = await this.loadStripe(this.data.get('publishableKey'))
    this.disablePay()

    if (this.intentType === 'confirmation') {
      this.scaConfirmation()
      return
    }

    // Create an instance of the card Element
    this.stripeElements = this.stripe.elements()

    this.cardNumber = this.stripeElements.create('cardNumber', {
      style
    })

    this.cardExpiry = this.stripeElements.create('cardExpiry', {
      style
    })

    this.cardCvc = this.stripeElements.create('cardCvc', {
      style
    })

    // mount the stripeElements
    this.cardNumber.mount(this.cardNumberTarget)
    this.cardExpiry.mount(this.cardExpiryTarget)
    this.cardCvc.mount(this.cardCvcTarget)

    // listen for errors
    this.cardNumber.addEventListener('change', this.handleCardError)
    this.cardExpiry.addEventListener('change', this.handleCardError)
    this.cardCvc.addEventListener('change', this.handleCardError)

    // setup events to track form completion
    this.cardNumber.on('change', this.cardCompleteHandler.bind(this))
    this.cardExpiry.on('change', this.cardExpiryCompleteHandler.bind(this))
    this.cardCvc.on('change', this.cardCvcCompleteHandler.bind(this))
    this.submitTarget.disabled = true
  }

  cardCompleteHandler(e) {
    this.cardComplete.number = e.complete
    this.disablePay(!this.isCardComplete)
  }

  cardExpiryCompleteHandler(e) {
    this.cardComplete.expiry = e.complete
    this.disablePay(!this.isCardComplete)
  }

  cardCvcCompleteHandler(e) {
    this.cardComplete.cvc = e.complete
    this.disablePay(!this.isCardComplete)
  }

  get isCardComplete() {
    return this.cardComplete.number && this.cardComplete.expiry && this.cardComplete.cvc
  }

  disconnect() {
    this.cardNumber?.removeEventListener('change', this.handleCardError)
    this.cardExpiry?.removeEventListener('change', this.handleCardError)
    this.cardCvc?.removeEventListener('change', this.handleCardError)
  }

  handleCardError = ({ error }) => {
    this.disablePay(false)
    if (error) {
      Sentry?.captureException(new Error(error.message))
      if (error.type == 'invalid_request_error') {
        this.cardErrorTarget.textContent = 'Veuillez renseigner toutes les informations'
      } else {
        this.cardErrorTarget.textContent = error.message
      }
    } else {
      this.cardErrorTarget.textContent = ''
    }
  }

  togglePay(e) {
    this.disablePay(!e.currentTarget.checked)
  }

  disablePay(value) {
    this.payTarget.disabled = value
    this.payTarget.classList.toggle('disabled', value)
  }

  handleSubmit = async event => {
    if (event.currentTarget.disabled) return
    this.disablePay(true)
    event.stopPropagation()
    event.preventDefault()

    switch (this.intentType) {
      case 'payment':
        this.handleCardPayment()
        break
      case 'setup':
        this.handleCardSetup()
        break
      case 'create':
        this.handleCardCreate()
        break
      case 'token':
        this.handleCardToken()
        break
    }
  }

  handleCardPayment() {
    this.stripe
      .handleCardPayment(this.clientSecret, this.cardNumber, {
        payment_method_data: {
          billing_details: {
            name: this.lastNameFieldValue,
            email: this.email
          }
        }
      })
      .then(result => {
        if (result.error) {
          this.handleCardError(result)
        } else {
          this.submitTarget.disabled = false
          this.submitTarget.click()
        }
      })
  }

  handleCardCreate() {
    this.stripe
      .createPaymentMethod('card', this.cardNumber, {
        billing_details: {
          name: this.lastNameFieldValue,
          email: this.email
        }
      })
      .then(result => {
        if (result.error) {
          this.handleCardError(result)
        } else {
          this.paymentMethodInputTarget.value = result.paymentMethod.id
          this.submitTarget.disabled = false
          this.submitTarget.click()
        }
      })
  }

  handleCardSetup() {
    this.stripe
      .handleCardPayment(this.clientSecret, this.cardNumber, {
        payment_method_data: {
          billing_details: {
            name: this.lastNameFieldValue,
            email: this.email
          }
        }
      })
      .then(result => {
        if (result.error) {
          this.handleCardError(result)
        } else {
          this.submitTarget.disabled = false
          this.submitTarget.click()
        }
      })
  }

  handleCardToken() {
    this.stripe.createToken(this.cardNumber).then(result => {
      if (result.error) {
        this.handleCardError(result)
      } else {
        this.tokenInputTarget.value = result.token.id
        this.submitTarget.disabled = false
        this.submitTarget.click()
      }
    })
  }

  scaConfirmation() {
    this.stripe.handleCardPayment(this.clientSecret).then(result => {
      if (result.error) {
        location.href = this.failureUrl
      } else {
        location.href = this.successUrl
      }
    })
  }

  get successUrl() {
    return this.data.has('successUrl') ? this.data.get('successUrl') : ''
  }

  get failureUrl() {
    return this.data.has('failureUrl') ? this.data.get('failureUrl') : ''
  }

  get intentType() {
    return this.data.has('intentType') ? this.data.get('intentType') : 'payment'
  }

  get clientSecret() {
    return this.data.has('secret') ? this.data.get('secret') : ''
  }

  get emailFieldValue() {
    return this.hasEmailTarget && this.emailTarget.value
  }

  get lastNameFieldValue() {
    return this.hasLastNameTarget && this.lastNameTarget.value
  }
}
