import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { active: Boolean, navigatable: Boolean }

  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick.bind(this))
  }

  handleClick(event) {
    if (!this.navigatableValue) {
      event.preventDefault()
      return
    }

    this.activeValue = true
    this.#sibilings.forEach(controller => {
      controller.activeValue = false
    })
  }

  // callbacks
  activeValueChanged(value) {
    this.element.classList.toggle('active', value)
  }

  // getters
  get #sibilings() {
    return this.application.controllers.filter(
      controller => controller.identifier == this.identifier && controller != this
    )
  }
}
