import { Application } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
application.stimulusUseDebug = process.env.NODE_ENV === 'development'
window.Stimulus = application

const defaultErrorHandler = application.handleError.bind(application)

const sentryErrorHandler = (error, message, detail = {}) => {
  defaultErrorHandler(error, message, detail)
  Sentry.captureException(error, { message, ...detail })
}

application.handleError = sentryErrorHandler

// remove Stimulus depreciation warnings in production
if (process.env.NODE_ENV === 'production') {
  application.logger = { ...application.logger, warn: () => {} }
}

export { application }
