import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select']
  static values = { create: Boolean }

  connect() {
    import(/* webpackChunkName: "tom-select" */ 'tom-select').then(Module => {
      const TomSelect = Module.default
      this.select = new TomSelect(this.selectElement, {
        create: this.hasCreateValue ? this.createValue : false,
        sortField: {
          field: 'text',
          direction: 'asc'
        },
        render: {
          option_create: function (data, escape) {
            return (
              '<div class="create">Ajouter <strong>' +
              escape(data.input) +
              '</strong>&hellip;</div>'
            )
          }
        }
      })
    })
  }

  get selectElement() {
    return this.hasSelectTarget ? this.selectTarget : this.element
  }
}
