import { Controller } from '@hotwired/stimulus'
import autoAnimate from '@formkit/auto-animate'

export default class extends Controller {
  connect() {
    this.animator = autoAnimate(this.element)
  }

  disconnect() {
    this.animator?.destroy
  }
}
