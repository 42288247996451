import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['paragraph', 'button', 'link']

  displayParagraph() {
    if (this.paragraphTargets[0].classList.contains('hidden')) {
      this.paragraphTargets[0].classList.remove('hidden')
    } else if (this.paragraphTargets[1].classList.contains('hidden')) {
      this.paragraphTargets[1].classList.remove('hidden')
    } else if (this.paragraphTargets[2].classList.contains('hidden')) {
      this.paragraphTargets[2].classList.remove('hidden')
    } else if (this.paragraphTargets[3].classList.contains('hidden')) {
      this.paragraphTargets[3].classList.remove('hidden')
      this.buttonTarget.classList.add('!hidden')
      this.linkTarget.classList.remove('!hidden')
      this.linkTarget.classList.add('!block')
    }
  }
}
