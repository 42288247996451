import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit']
  static values = { subscriptionPlanId: String }

  enable(e) {
    if (this.hasSubmitTarget) {
      const { planId } = e.currentTarget.dataset

      this.submitTarget.disabled = planId === this.subscriptionPlanIdValue
    }
  }

  updateActionUrl(e) {
    const { actionUrl } = e.currentTarget.dataset
    this.element.action = actionUrl
  }
}
