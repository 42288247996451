import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ['content']

  initialize() {
    this.text = ''
    this.#toogleButtonRequiringText()
  }

  connect() {}

  // outlet method
  updateText(text) {
    this.text = text
    // this method is call from the tip-tap controller using an outlet
    this.contentTargets.forEach(target => {
      target.value = text
    })
    this.#toogleButtonRequiringText()
  }

  // private

  #toogleButtonRequiringText() {
    this.element.querySelectorAll('[data-text-required]').forEach(element => {
      if (element.tagName === 'A') {
        // It is not possible to simply add a disabled attribute to a link tag
        // we need to add teh class and remove the href
        // we also need to add the aria-disabled attribute
        // we want to keep the href in a data-href attribute so that we can set it back when the link is enabled
        if (this.emptyText) {
          element.removeAttribute('href')
        } else {
          element.setAttribute('href', element.dataset.href)
        }

        element.setAttribute('aria-disabled', this.emptyText)
      } else {
        element.disabled = this.emptyText
      }

      element.classList.toggle('disabled', this.emptyText)
    })
  }

  get emptyText() {
    return this.text.length <= 9
  }
}
