import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="event"
export default class extends Controller {
  static values = {
    dispatchOnConnect: { type: Boolean, default: false },
    name: String
  }

  connect() {
    if (this.dispatchOnConnectValue) {
      this.dispatch(this.nameValue, { prefix: false })
    }
  }

  emit() {
    this.dispatch(this.nameValue, { prefix: false })
  }
}
