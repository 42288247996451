import { Controller } from '@hotwired/stimulus'
import sbjs from 'sourcebuster/dist/sourcebuster'

export default class extends Controller {
  static targets = ['field']
  connect() {
    sbjs.init()
  }
  submit(e) {
    this.fieldTarget.value = JSON.stringify(sbjs.get)
  }
}
