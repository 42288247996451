import Controller from './application_controller'

export default class extends Controller {
  static targets = ['textarea']
  static values = { text: String, computedCorrectionText: String }

  computedCorrection = true

  // actions

  reset(e) {
    this.textareaTarget.value = this.textValue
  }

  toggle(e) {
    if (this.computedCorrection) {
      this.textareaTarget.value = this.textValue
      e.currentTarget.innerText = 'Afficher la correction automatique'
    } else {
      this.textareaTarget.value = this.computedCorrectionTextValue
      e.currentTarget.innerText = 'Annuler la correction automatique'
    }
    this.computedCorrection = !this.computedCorrection
  }
}
