import { Controller } from '@hotwired/stimulus'
import { useApplication, useMeta } from 'stimulus-use'

export default class extends Controller {
  static metaNames = [
    'kid_id',
    'story_id',
    'kid_story_id',
    'chapter_id',
    'user_account_type',
    'first_name',
    'last_name',
    'email',
    'user_id',
    'admin',
    'ios_app',
    'mobile_application',
    'environment',
    'tester',
    'in_app_purchase'
  ]

  constructor(context) {
    super(context)
    useApplication(this, this.options)
    useMeta(this, { suffix: false })
  }

  handleError = error => {
    const context = {
      controller: this.identifier,
      user_id: this.userId,
      kid_id: this.kidId
    }
    Sentry.configureScope(scope => {
      scope.setExtra('kid_id', this.kidId)
      scope.setExtra('chapter_id', this.chapterId)
      scope.setExtra('story_id', this.storyId)
      scope.setExtra('url', window.location.href)
    })

    if (this.application) {
      this.application.handleError(error, `Error in controller: ${this.identifier}`, context)
    } else {
      // in some cases this.application is not defined???
      Sentry.captureException(error)
    }
  }

  // get admin() {
  //   return this.metaValue('admin') === 'true'
  // }

  // get kidId() {
  //   return this.metaValue('kid_id')
  // }

  // get storyId() {
  //   return this.metaValue('story_id')
  // }

  // get kidStoryId() {
  //   return this.metaValue('kid_story_id')
  // }

  // get storyTitle() {
  //   return this.metaValue('story_title')
  // }

  // get chapterTitle() {
  //   return this.metaValue('chapter_title')
  // }

  // get chapterId() {
  //   return this.metaValue('chapter_id')
  // }

  // get pageId() {
  //   return this.metaValue('page_id') || 1
  // }

  // get environment() {
  //   return this.metaValue('environment')
  // }

  // get iosApp() {
  //   return this.metaValue('ios_app') === 'true'
  // }

  // get userId() {
  //   return this.metaValue('user_id') || this.metaValue('current_user_id')
  // }
}
