import Controller from './game_controller'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['letter', 'errorMessage', 'gameIdField']
  static values = {
    answer: String,
    autofocus: Boolean,
    completed: Boolean
  }
  selectedInputIndex = 0

  initialize() {
    // we must define it again otherwise it is overwriten by Stimulus default Dispacth function
    useDispatch(this, { eventPrefix: false })
  }

  connect() {
    if (this.completedValue) return

    if (this.autofocusValue) {
      requestAnimationFrame(() => {
        this.letterTargets[0].select()
      })
    }
  }

  // actions
  next(e) {
    const { inputType } = e
    if (inputType !== 'deleteContentBackward') {
      const index = parseInt(e.target.dataset.index)
      requestAnimationFrame(() => {
        this.focusInput(index + 1)
        this.dispatch('play:sound', { randomSounds: ['kick1', 'kick2'] })
      })
    }
    this.dispatch('tip:close')
  }

  delete(e) {
    const index = parseInt(e.target.dataset.index)
    if (e.key === 'Backspace' && e.target.value.length == 0) {
      this.focusInput(index - 1)
      this.dispatch('play:sound', { randomSounds: ['kick1', 'kick2'] })
    }
  }

  back(e) {
    const index = parseInt(e.target.dataset.index)
    if (e.key === 'ArrowLeft') {
      this.focusInput(index - 1)
      this.dispatch('play:sound', { randomSounds: ['kick1', 'kick2'] })
    }
  }

  select(e) {
    const index = parseInt(e.target.dataset.index)
    if (e.type == 'click') {
      this.focusInput(index)
      this.dispatch('play:sound', { randomSounds: ['kick1', 'kick2'] })
    }
  }

  focus(e) {
    // this is used to display the correct acrostiche help master in the mutli acrostiche mode
    this.dispatch(`${this.gameIdValue}:focus`)
  }

  // private
  focusInput(index) {
    if (index >= 0 && index < this.answerValue.length) {
      this.selectedInputIndex = index
    }
    this.validate()
    requestAnimationFrame(() => {
      const input = this.letterTargets[index]
      input?.select()
    })
  }

  validate = () => {
    const isFinalLetter = this.selectedInputIndex === this.answerValue.length - 1
    const isAnswerCorrect = this.contribution === this.answerValue
    const isCompleted = this.contribution.length === this.answerValue.length
    const isAlmostCorrect = removeAccents(this.contribution) === removeAccents(this.answerValue)
    const index = this.selectedInputIndex
    if (isFinalLetter && isAnswerCorrect) {
      this.gameIdFieldTarget.value = this.gameIdFieldTarget.dataset.gameId
      this.success()
    } else if (isFinalLetter && isAlmostCorrect) {
      // this.almost()
      // we decided not to check for accents anymore
      this.gameIdFieldTarget.value = this.gameIdFieldTarget.dataset.gameId
      this.success()
    } else if (isCompleted && isFinalLetter && !isAnswerCorrect) {
      findWrongLetters(this.contribution, this.answerValue, this.letterTargets)
      this.error()
    } else {
      this.letterTargets[index].classList.remove('error')
    }
  }

  //getters
  get contribution() {
    return this.letterTargets
      .map(input => input.value)
      .join('')
      .toLowerCase()
  }

  get activeLetterTargets() {
    return this.letterTargets.filter(input => input.disabled !== true) || []
  }
}

function removeAccents(string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function findWrongLetters(word, answer, letters) {
  word.split('').forEach((enteredLetter, i) => {
    if (removeAccents(enteredLetter) !== removeAccents(answer[i])) {
      letters[i].classList.add('error')
      letters[i].classList.remove('success')
    } else {
      letters[i].classList.remove('error')
      letters[i].classList.add('success')
    }
  })
}
