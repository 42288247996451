import Controller from './application_controller'

export default class extends Controller {
  static values = {
    destination: String,
    action: { type: String, default: 'beforeend' }
  }

  initialize() {
    if (!this.isPreview) {
      const destination = document.querySelector(this.destinationValue)
      destination.insertAdjacentHTML(this.actionValue, this.element.innerHTML)
    }
  }
}
