// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
import Rails from '@rails/ujs'

const old_confirm = Rails.confirm

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]']

const createConfirmModal = element => {
  const id = 'confirm-modal-' + String(Math.random()).slice(2, -1)
  const confirm = element.dataset.confirm
  const icon = element.dataset.icon
  const message = element.dataset.message
  const commit = element.dataset.commit || 'Je confirme'
  const cancel = element.dataset.cancel || 'Annuler'
  const buttonColor = element.dataset.confirmButtonColor || 'primary'

  const modalIcon = `
    <div class="mx-auto mb-6 fill-${buttonColor}">
      ${icon}
    </div>
  `

  const content = `
    <div class="modal slide-up"
          data-controller="modal"
          data-modal-open="true" id="${id}"
          tabindex="-1"
          role="dialog"
          aria-labelledby="confirmationModdal"
          aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered !max-w-lg mobile-bottom" role="document">
        <div class="modal-content p-6 !rounded-2xl !shadow-lg !border-0">
          <div class="modal-body text-center">
            <div class="mx-auto fill-${buttonColor}">
              ${icon ? icon : ''}
            </div>
            <div class="title--lg">${confirm}</div>
            <div class="body--lg text-gray my-6 whitespace-break-spaces">${
              message ? message : ''
            }</div>
          </div>
          <div class="flex justify-between">
            <button data-behavior="cancel"
                    type="button"
                    class="primer--button w-1/2 outlined mr-4"
                    data-dismiss="modal">
                      ${cancel}
            </button>
            <button data-behavior="commit"
                    type="button"
                    class="primer--button w-1/2 ${buttonColor}"
                    data-dismiss="modal">
                    ${commit}
            </button>
          </div>
        </div>
      </div>
    </div>
  `
  document.body.insertAdjacentHTML('afterend', content)

  var modal = document.querySelector(`#${id}`)
  element.dataset.confirmModal = `#${id}`

  const closeModal = () => {
    $(`#${id}`).modal().hide()
  }

  $(`#${id}`).on('hidden.bs.modal', e => {
    element.removeAttribute('data-confirm-modal')
  })

  document.addEventListener('keyup', event => {
    if (event.key === 'Escape') {
      event.preventDefault()
      closeModal()
    }
  })

  modal.querySelector("[data-behavior='cancel']").addEventListener('click', event => {
    event.preventDefault()
    closeModal()
  })

  modal.querySelector("[data-behavior='commit']").addEventListener('click', event => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => {
      return true
    }

    // Click the link again
    element.click()

    // Restore the confirm behavior
    Rails.confirm = old_confirm

    closeModal()
  })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = element => {
  return !!element.dataset.confirmModal
}

export const handleConfirm = event => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
