import { TemplateInstance } from '@github/template-parts'

const defaultOptions = {}

export const useTemplate = (controller, options = {}) => {
  const {} = {
    ...defaultOptions,
    ...options
  }

  const element = options.element || controller.element
  const template = options.template || controller.element.querySelector('template')
  const viewElement =
    options.viewElement || (controller.hasViewTarget && controller.viewTarget) || element
  const values = options.values || []
  const controllerDisconnect = controller.disconnect

  Object.assign(controller, {
    renderTemplate(values = []) {
      viewElement.innerHTML = ''
      // const templateContent = template.content.cloneNode(true)
      // TemplateInstance.render(templateContent, values)
      values.forEach(value => {
        const templateContent = new TemplateInstance(template, value)
        viewElement.appendChild(templateContent)
      })
    },
    disconnect() {
      controllerDisconnect()
    }
  })

  controller.renderTemplate(values)
}
