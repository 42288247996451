import Highlight from '@tiptap/extension-highlight'
import { getMarkRange, getMarkType } from '@tiptap/core'

const CustomHighlight = Highlight.extend({
  addOptions() {
    return {
      isKid: false
    }
  },
  parseHTML() {
    return [
      {
        tag: 'mark',
        getAttrs: node => {
          // If the node has a data-color attribute, it means it's a highlight node
          // this is used to differentiate with diff marks
          return node.getAttribute('data-color') ? {} : false
        }
      }
    ]
  },
  onSelectionUpdate({ editor }) {
    if (!this.options.isKid) return

    const regex = /-?(\w+)$/

    let color = editor.getAttributes('highlight')?.color?.match(regex)[1]

    const currentAnchor = editor.state.selection
    const currentSchema = editor.state.schema
    // TODO: get the closest mark range when head is in front of a mark
    let mark = getMarkRange(currentAnchor.$from, getMarkType('highlight', currentSchema))

    if (!mark || !color) return

    try {
      editor
        .chain()
        .setTextSelection(mark)
        .command(({ commands }) => {
          commands.updateAttributes('highlight', { color: `border-${color}` })
        })
        .setTextSelection(currentAnchor)
        .run()
    } catch (error) {
      this.handleError(error)
    }
  },
  onUpdate({ editor, transaction }) {
    if (Object.keys(editor.getAttributes('highlight')).length) {
      return
    }

    const currentSchema = editor.state.schema
    const { selection } = transaction
    const { from, to } = selection
    const pos = from + 1
    const resolvedPos = transaction.doc.resolve(pos)
    const marks = resolvedPos.marks().map(mark => mark.attrs?.color)
    const color = marks[0]
    const mark = getMarkRange(resolvedPos, getMarkType('highlight', currentSchema))

    if (!color || !mark) return

    editor
      .chain()
      .setTextSelection({ from: from - 1, to: mark.to })
      .setHighlight({ color: this.options.isKid ? `border-${color}` : color })
      .setTextSelection({ from: to, to: to })
      .run()
  },
  addCommands() {
    return {
      ...this.parent?.(), // inherit parent commands
      unsetHighlightForLastWord:
        key =>
        ({ commands, editor }) => {
          if (key != ' ' && key != 'ArrowRight') return
          if (editor.getText().length > editor.state.selection.$head.pos) return
          if (!editor.isActive('highlight')) return

          return commands.unsetHighlight()
        }
    }
  }
})

export default CustomHighlight
