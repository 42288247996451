import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['id', 'image']

  select(e) {
    this.unselectAll()
    e.currentTarget.classList.add('avatar__selected')
    this.idTarget.value = e.currentTarget.dataset.id
  }

  upload(e) {
    this.unselectAll()
    this.idTarget.value = ''
  }

  unselectAll() {
    this.imageTargets.forEach(image => image.classList.remove('avatar__selected'))
  }

  get avatarIndex() {
    return this.idTarget.value || -1
  }
}
