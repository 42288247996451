import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['months', 'days', 'hours', 'minutes']
  static values = { format: String, endDate: String }

  initialize() {
    this.updateCountDown = this.updateCountDown.bind(this)
  }

  connect() {
    this.startCountdown()
  }

  disconnect() {
    this.stopCountdown()
  }

  startCountdown() {
    this.updateCountDown()
    this.interval = setInterval(
      this.updateCountDown,
      this.formatValue === 'detailed' ? 60000 : 1000
    )
  }

  stopCountdown() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  updateCountDown() {
    const distance = this.getDistance()

    if (distance > 0) {
      if (this.formatValue === 'detailed') {
        this.updateDetailedCountDown(distance)
      } else {
        this.updateOriginalCountDown(distance)
      }
    } else {
      this.stopCountdown()
      this.handleCountdownEnd()
    }
  }

  updateOriginalCountDown(distance) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    this.element.textContent = `Dans ${days}j ${hours}h ${minutes}m ${seconds}s`
  }

  updateDetailedCountDown(distance) {
    const months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30.44))
    const days = Math.floor((distance % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

    this.monthsTarget.textContent = months.toString().padStart(2, '0')
    this.daysTarget.textContent = days.toString().padStart(2, '0')
    this.hoursTarget.textContent = hours.toString().padStart(2, '0')
    this.minutesTarget.textContent = minutes.toString().padStart(2, '0')
  }

  handleCountdownEnd() {
    if (this.formatValue === 'detailed') {
      this.monthsTarget.textContent = '00'
      this.daysTarget.textContent = '00'
      this.hoursTarget.textContent = '00'
      this.minutesTarget.textContent = '00'
    } else {
      this.element.textContent = 'Le compte à rebours est terminé'
    }
  }

  getDistance() {
    const now = new Date().getTime()
    return new Date(this.endDateValue).getTime() - now
  }
}
