import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['wordCount', 'target']
  static values = { target: Object, wordCount: Number }

  connect() {
    this.handleUpdate(this.wordCountValue)
  }

  // action
  updateCount(event) {
    this.handleUpdate(event.detail.wordCount)
  }

  // private

  handleUpdate(wordCount) {
    this.wordCountTarget.innerText = wordCount
    this.progessBarElement.style.width = `${
      (wordCount / Math.max(...Object.values(this.targetValue))) * 100
    }%`
    if (
      (this.targetValue.min <= 1 || wordCount >= this.targetValue.min) &&
      (this.targetValue.max < 1 || wordCount <= this.targetValue.max)
    ) {
      this.progessBarElement.classList.add('bg-green')
      this.progessBarElement.classList.remove('bg-orange')
    } else {
      this.progessBarElement.classList.add('bg-orange')
      this.progessBarElement.classList.remove('bg-green')
    }
  }

  get progessBarElement() {
    return this.wordCountTarget.parentNode
  }
}
