import Controller from './application_controller'

export default class extends Controller {
  static targets = ['slider', 'slide']

  initialize() {
    this.showCurrentSlide()
  }

  connect() {
    this.element[this.identifier] = this
  }

  poussin() {
    if (this.index === 0) {
      if (typeof gtag === 'function') {
        gtag('event', 'hints_first_slide', {
          event_category: 'hints',
          event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
        })
        gtag('event', 'hints_start', {
          event_category: 'hints',
          event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
        })
      }
      this.index++
    } else if (this.sliderTarget.classList.contains('hidden')) {
      if (typeof gtag === 'function') {
        gtag('event', 'hints_continue', {
          event_category: 'hints',
          event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
        })
      }
      this.sliderTarget.classList.remove('hidden')
    } else {
      if (typeof gtag === 'function') {
        gtag('event', 'hints_close', {
          event_category: 'hints',
          event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
        })
      }
      this.hideSlider()
    }
  }

  next(e) {
    e.preventDefault()
    this.index++
    if (typeof gtag === 'function') {
      gtag('event', 'hints_next_to_slide_' + this.index, {
        event_category: 'hints',
        event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
      })
    }
  }

  previous(e) {
    e.preventDefault()
    this.index--
    if (typeof gtag === 'function') {
      gtag('event', 'hints_previous_to_slide_' + this.index, {
        event_category: 'hints',
        event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
      })
    }
  }

  hideSlider() {
    this.sliderTarget.classList.add('hidden')
  }

  showCurrentSlide(index) {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle('bubble-slide--current', this.index == i)
    })
  }

  get index() {
    return parseInt(this.data.get('index'))
  }

  set index(value) {
    this.data.set('index', value)
    this.showCurrentSlide()
  }
}
