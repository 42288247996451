import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['step', 'next', 'previous', 'submit']

  initialize() {
    this.showCurrentStep()
  }

  next() {
    this.index++
  }

  previous() {
    this.index--
  }

  showCurrentStep() {
    this.stepTargets.forEach((el, i) => {
      el.classList.toggle('step--current', this.index == i)
    })
    this.updateControls()
  }

  updateControls() {
    this.previousTarget.classList.toggle('hidden', this.isfirstStep)
    this.nextTarget.classList.toggle('hidden', this.islastStep)
    this.submitTarget.classList.toggle('hidden', !this.islastStep)
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', value)
    this.showCurrentStep()
  }

  get islastStep() {
    return this.index === this.stepTargets.length - 1
  }

  get isfirstStep() {
    return this.index === 0
  }
}
