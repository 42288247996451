import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['activity', 'unlockActivity']

  notifyUser() {
    this.lastUnlockedActivity.classList.add('animate-heart-beat')

    setTimeout(() => {
      this.lastUnlockedActivity.classList.remove('animate-heart-beat')
    }, 1000)
    this.dispatch('play:sound', { prefix: false, detail: { sound: 'gong', volume: '0.3' } })
  }

  get lastUnlockedActivity() {
    return this.unlockActivityTargets[this.unlockActivityTargets.length - 1]
  }
}
