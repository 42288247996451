import './vendor/polyfills'

import Rails from '@rails/ujs'
window.Rails = Rails

import './vendor/bootstrap'
import './vendor/sentry'
import './vendor/turbo'
import './controllers'
import './vendor/active_storage'
import './vendor/analytics'
import './cookies_scripts'
// start UJS
if (!window._rails_loaded) {
  window.Rails.start()
}
import './components/tooltips'
import './components/confirmation'

if (process.env.NODE_ENV === 'development') {
  require('@khanacademy/tota11y')
}
// TODO probably legacy code not used anymore, there is a dedicated stimulus controller for that
import copy from 'copy-to-clipboard'
window.copy = copy

document.addEventListener('turbo:load', function () {
  // source https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
