import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js/auto'

export default class ChartJs extends Controller {
  static targets = ['canvas']
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    data: Object,
    options: Object
  }

  connect() {
    requestAnimationFrame(() => {
      const element = this.hasCanvasTarget ? this.canvasTarget : this.element

      this.chart = new Chart(element.getContext('2d'), {
        type: this.typeValue,
        data: this.chartData,
        options: this.chartOptions
      })
    })
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
      this.chart = undefined
    }
  }

  dataValueChanged() {
    if (this.chart) {
      this.chart.data = this.dataValue
      this.chart.update()
    }
  }

  get chartData() {
    return this.dataValue
  }

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {}
  }
}
