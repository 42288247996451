import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    animationData: Object,
    animationFile: String,
    loop: Boolean,
    autoplay: Boolean
  }

  connect() {
    this.element.lottieController = this

    const animationSource = this.hasAnimationDataValue
      ? { animationData: this.animationDataValue }
      : { path: this.animationFileValue }

    const params = {
      container: this.element,
      renderer: 'svg',
      loop: this.loopValue,
      autoplay: this.autoplayValue
    }

    import(/* webpackChunkName: "lottie-web" */ 'lottie-web').then(lottie => {
      this.lottie = lottie.default
      this.animation = this.lottie.loadAnimation(Object.assign({}, params, animationSource))
    })
  }

  play() {
    this.animation?.play()
  }

  pause() {
    this.animation?.pause()
  }

  stop() {
    this.animation?.stop()
  }
}
