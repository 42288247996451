export class TextTransform {
  constructor(controller) {
    this.controller = controller
    this.capitalizeRegex = /^[A-Za-zÀ-ÿ]/
    this.endSentenceRegex = /[.!?]\s*\n*\r*/g
  }

  capitalizeStartsOfSentences() {
    let array = Array.from(this.controller.sentenceTarget.querySelectorAll('.composed-word'))

    array.forEach((element, index) => {
      if (index == 0 || (index > 0 && this.endSentenceRegex.test(array[index - 1].textContent))) {
        this.capitalize(element)
      } else {
        this.uncapitalize(element)
      }
    })
  }

  capitalize(element) {
    if (!element) return

    element = this.getContentElement(element)
    element.dataset.original ||= element.textContent
    element.textContent = element.textContent
      .trimStart()
      .replace(this.capitalizeRegex, c => c.toUpperCase())
  }

  uncapitalize(element) {
    if (!element) return

    element = this.getContentElement(element)
    if (element.dataset.original) {
      element.textContent = element.dataset.original
    }
  }

  getContentElement(element) {
    return (
      element.querySelector('.raw_content') ||
      element.querySelector('[data-primer--text-to-speech--component-target="content"]') ||
      element.querySelector('[data-primer--text-to-speech--component-target="icon content"]') ||
      element
    )
  }

  processMultilineText(text) {
    const lines = text.split(/\n/).filter(line => line.trim().length > 0)

    lines.forEach((line, lineIndex) => {
      const words = line.split(/\s+/).filter(word => word.length > 0)

      words.forEach((word, wordIndex) => {
        const lastEditable = this.controller.sentenceTarget.querySelector('.editable:last-of-type')

        lastEditable.textContent = word
        lastEditable.classList.add('word', 'composed-word', 'user-composed')
        lastEditable.classList.remove('editable')
        lastEditable.classList.add(...this.controller.editableClasses)

        this.controller.editableZone.addBefore(lastEditable)
        this.controller.editableZone.addAfter(lastEditable)

        if (wordIndex === words.length - 1 && lineIndex < lines.length - 1) {
          const editable = this.controller.sentenceTarget.querySelector('.editable:last-of-type')
          editable.after(this.controller.editableZone.lineBreakTemplate)
          const lineBreak = editable.nextElementSibling
          this.controller.editableZone.addAfter(lineBreak)
        }
      })
    })

    const lastEditable = this.controller.sentenceTarget.querySelector('.editable:last-of-type')
    if (lastEditable) {
      lastEditable.focus()
      this.controller.editableZone.setEndOfContenteditable(lastEditable)
    }
  }
}
