import { Sortable } from '@shopify/draggable'
import { SortAnimation } from '../sort_animation'
import { ExcludedClasses } from '../excluded_classes'

export class DragDrop {
  constructor(controller) {
    this.controller = controller
    this.draggable = null
  }

  init() {
    const containers = [
      this.controller.composableTarget,
      this.controller.sentenceTarget,
      this.controller.trashTarget
    ]

    this.draggable = new Sortable(containers, {
      draggable: '.word',
      sortAnimation: {
        duration: 100,
        easingFunction: 'ease-in-out'
      },
      delay: {
        mouse: 100,
        drag: 0,
        touch: 100
      },
      classes: {
        mirror: ['opacity-50', 'z-30', 'draggable-mirror'],
        'source:dragging': ['opacity-50', '!border-green', '!bg-green-light']
      },
      plugins: [ExcludedClasses, SortAnimation],
      dragDistance: 5
    })

    this.draggable.on('drag:start', this.handleDragStart.bind(this))
    this.draggable.on('drag:stopped', this.handleDragStop.bind(this))
    this.draggable.on('drag:over:container', this.handleHover.bind(this))
    this.draggable.on('drag:out:container', this.handleOut.bind(this))
  }

  handleDragStart(event) {
    this.tooltipSibilings.forEach(tooltip => tooltip.disable())
    this.toggleGrabCursor(true, event.data.source)
  }

  handleHover(event) {
    this.magnifyTrash(event.data, 'hover')
    this.preventResizing()
  }

  handleOut(event) {
    this.magnifyTrash(event.data, 'out')
    this.enableResizing()
  }

  handleDragStop(event) {
    this.transformWord(event.data)
    this.controller.paramsHandler.buildParamsFromSentence()
    this.controller.editableZone.initZones()
    this.togglePlaceholder()
    this.enableResizing()
    this.tooltipSibilings.forEach(tooltip => tooltip.enable())
    this.toggleGrabCursor(false, event.data.source)
    this.controller.textTransform.uncapitalize(this.secondWordOfSentence)
    this.controller.textTransform.capitalizeStartsOfSentences()
    this.controller.paramsHandler.buildParamsFromSentence()
  }

  transformWord(data) {
    const element = data.originalSource
    const target = data.originalSource.parentNode
    const source = data.sourceContainer
    if (source.id == 'composable' && target.id == 'sentence') {
      element.classList.add('composed-word')
      target.classList.remove('bg-blue-light')
      target.classList.add('bg-white')
    } else if (source.id == 'sentence' && target.id == 'trash') {
      this.cloneWordOnTrash(element)
      element.classList.add('trash-on-drag')
    } else if (source.id == 'sentence' && target.id == 'composable') {
      element.classList.remove('composed-word')
    } else if (source.id == 'composable' && target.id == 'trash') {
      this.cloneWordOnTrash(element)
      element.classList.add('trash-on-drag')
    }
  }

  cloneWordOnTrash(element) {
    const clone = element.cloneNode(true)
    const trash = this.controller.trashTarget.querySelector('#trash-icon')
    clone.classList.remove(
      'gu-transit',
      'composed-word',
      'gu-transit-hidden',
      'draggable-source--is-dragging',
      'draggable--over'
    )
    element.remove()
    if (!clone.classList.contains('user-composed')) {
      this.controller.composableTarget.appendChild(clone)
    }
    trash.classList.remove('fill-red', 'h-5')
    trash.classList.add('fill-gray-lighter', 'h-4')
  }

  toggleGrabCursor(toggle, element) {
    if (toggle) {
      element.classList.add('cursor-grab')
    } else {
      element.classList.remove('cursor-grab')
    }
  }

  togglePlaceholder() {
    if (this.controller.sentenceTarget.querySelectorAll('.composed-word').length > 0) {
      this.controller.placeholderTarget.classList.add('hidden')
    } else {
      this.controller.placeholderTarget.classList.remove('hidden')
    }
  }

  magnifyTrash(datas, state) {
    if (datas.overContainer.id == 'trash') {
      const element = datas.source
      const container = datas.overContainer
      if (state == 'hover') {
        container.querySelector('#trash-icon').classList.add('fill-red', 'h-5')
        container.querySelector('#trash-icon').classList.remove('fill-gray-lighter', 'h-4')
        element.classList.add('draggable-source--hide')
        const mirror = document.querySelector('.draggable-mirror')
        mirror.classList.add('!border-red', 'opacity-30')
      } else if (state == 'out') {
        container.querySelector('#trash-icon').classList.remove('fill-red', 'h-5')
        container.querySelector('#trash-icon').classList.add('fill-gray-lighter', 'h-4')
        element.classList.remove('draggable-source--hide')
        const mirror = document.querySelector('.draggable-mirror')
        mirror.classList.remove('!border-red', 'opacity-30')
      }
    }
  }

  preventResizing() {
    const height = this.controller.sentenceTarget.offsetHeight
    this.controller.sentenceTarget.style.height = height + 'px'
  }

  enableResizing() {
    this.controller.sentenceTarget.style.height = null
  }

  // getters
  get tooltipSibilings() {
    return this.application.controllers.filter(
      controller => controller.identifier == 'primer--tooltip--component'
    )
  }

  get secondWordOfSentence() {
    firstWordOfSentence = this.controller.sentenceTarget.firstElementChild.nextElementSibling
    return firstWordOfSentence.nextElementSibling.nextElementSibling
  }
}
