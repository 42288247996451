import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ['card']
  static values = { id: Number }

  initialize() {
    useTransition(this, {
      element: this.cardTarget,
      enterActive: 'transition ease-in duration-300',
      enterFrom: 'transform opacity-0 scale-95',
      enterTo: 'transform opacity-100 scale-100',
      leaveActive: 'transition ease-in duration-150',
      leaveFrom: 'transform opacity-100 scale-100',
      leaveTo: 'transform opacity-0 scale-95',
      removeToClasses: false,
      hiddenClass: 'hidden'
    })
  }

  show(event) {
    event.preventDefault()

    this.timeout && clearTimeout(this.timeout)
    this.enter()
  }

  hide(event) {
    event.preventDefault()

    this.timeout = setTimeout(() => {
      this.leave()
    }, 150)
  }

  disconnect() {
    this.#hideCard()
  }

  #hideCard() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add('hidden')
    }
  }

  #showCard() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove('hidden')
    }
  }
}
