import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenField', 'input', 'priceRecap']

  apply(e) {
    const [_data, _status, xhr] = e.detail

    this.hiddenFieldTarget.value = this.inputTarget.value
    this.priceRecapTarget.outerHTML = xhr.response
  }
}
