import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { selector: String, key: { type: String, default: 'text' } }

  updateField(event) {
    const el = this.element.querySelector(this.selectorValue)

    if (el) {
      el.value = event.detail[this.keyValue]
    }
  }
}
