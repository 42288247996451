import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []
  static values = { texts: Array }

  setText() {
    this.element.textContent = this.randomText
  }

  get randomText() {
    return this.textsValue[Math.floor(Math.random() * this.textsValue.length)]
  }
}
