import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['firstName']

  updatePreview(event) {
    const { value } = event.currentTarget
    this.firstNameTarget.textContent = capitalize(value) || '....'
  }
}

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)
