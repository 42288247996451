import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'firstName',
    'addressFirstName',
    'addressLastName',
    'addressLineOne',
    'addressLineTwo',
    'addressPostCode',
    'addressCity',
    'content'
  ]

  updateFirstName(event) {
    const { value } = event.currentTarget
    this.firstNameTarget.textContent = capitalize(value) || '....'
  }

  updateContent(event) {
    let { value } = event.currentTarget
    value = value.replace(/(?:\r\n|\r|\n)/g, '<br>')
    this.contentTarget.innerHTML = capitalize(value) || '....'
  }

  updateAddressFirstName(event) {
    const { value } = event.currentTarget
    this.addressFirstNameTarget.textContent = capitalize(value) || '....'
  }

  updateAddressLastName(event) {
    const { value } = event.currentTarget
    this.addressLastNameTarget.textContent = capitalize(value) || '....'
  }

  updateAddressLineOne(event) {
    const { value } = event.currentTarget
    this.addressLineOneTarget.textContent = capitalize(value) || '....'
  }

  updateAddressLineTwo(event) {
    const { value } = event.currentTarget
    this.addressLineTwoTarget.textContent = capitalize(value)
  }

  updateAddressPostCode(event) {
    const { value } = event.currentTarget
    this.addressPostCodeTarget.textContent = capitalize(value) || '....'
  }

  updateAddressCity(event) {
    const { value } = event.currentTarget
    this.addressCityTarget.textContent = capitalize(value) || '....'
  }
}

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)
