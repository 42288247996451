import { useIntersection } from 'stimulus-use'
import Controller from './application_controller'

// use this controller to make advanced turbo frame lazy loading
// built in Turbo lazy loader does not allow to pass rootMargin value
// therefore the frame is loaded when the element enters the viewport without any
// possibility to load it in advance
//
// Usage
//
// <%= turbo_frame_tag "some_id", data: { controller: "turbo-lazy-load", turbo_lazy_load_src_value: some_path } do %>

export default class extends Controller {
  static values = {
    src: { type: String, default: '' },
    rootMargin: { type: String, default: '100px' }
  }

  loaded = false

  initialize() {
    if (this.isPreview) return

    useIntersection(this, { rootMargin: this.rootMarginValue })

    document.addEventListener('turbo:frame-missing', this.fallback.bind(this))
  }

  disconnect() {
    document.removeEventListener('turbo:frame-missing', this.fallback)
  }

  appear() {
    if (this.loaded) return

    this.element.src = this.srcValue
    this.loaded = true
  }

  fallback(e) {
    e.preventDefault()
    this.element.innerHTML = ''
  }
}
