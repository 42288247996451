import Controller from './application_controller'
import { post } from '@rails/request.js'
import { getMetaValue } from '../utils/index.js'
import { CookiePolicy, launchAsync } from '@microsoft/immersive-reader-sdk'

export default class extends Controller {
  uiZIndex = 1000000

  static values = {
    locale: { type: String, default: getMetaValue('locale') },
    paragraphs: String,
    title: String,
    subdomain: String
  }

  async openReader() {
    if (this.open) return

    try {
      this.open = true

      const token = await this.#fetchToken()
      launchAsync(token, this.subdomainValue, this.content, {
        uiLang: this.language,
        uiZIndex: this.uiZIndex,
        cookiePolicy: CookiePolicy.Disable,
        onExit: () => {
          this.open = false
          this.#removeLinkOverlay()
        }
      })
      this.#addLinkOverlay()
      this.sendGaEvent()
    } catch (error) {
      this.open = false
      this.#removeLinkOverlay()
      this.handleError(error)
    }
  }

  // private

  #addLinkOverlay() {
    // because there is a link to microsoft immersive reader in the bottom right corner of the page
    // we need to add a white div to cover it
    // raised an issue about it here: https://github.com/microsoft/immersive-reader-sdk/issues/494

    const div = document.createElement('div')
    div.id = 'immersive-reader-link-overlay'
    div.classList.add('bg-white', 'text-white', 'absolute', 'bottom-0', 'right-0')
    div.style.width = '340px'
    div.style.height = '50px'
    div.style.zIndex = this.uiZIndex * 2
    document.body.appendChild(div)
  }

  #removeLinkOverlay() {
    const div = document.getElementById('immersive-reader-link-overlay')
    div?.remove()
  }

  async #fetchToken() {
    return post('/api/v1/immersive_readers', { contentType: 'application/json' })
      .then(response => response.json)
      .then(json => json.access_token)
  }

  sendGaEvent() {
    if (typeof gtag === 'function') {
      gtag('event', 'initiate', {
        event_category: 'immersiveReader',
        event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
      })
    }
  }

  get content() {
    return {
      title: this.titleValue,
      chunks: [
        {
          content: this.paragraphsValue,
          lang: this.language,
          mimeType: 'text/plain'
        }
      ]
    }
  }

  get language() {
    return this.localeValue
  }
}
