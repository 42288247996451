import { Controller } from '@hotwired/stimulus'
import { timingByEnv, isPreview } from '../../javascript/utils'

export default class extends Controller {
  static targets = ['notification']

  initialize() {
    if (isPreview) return

    this.popupDelay = timingByEnv(800)
    this.pause = false
  }

  connect() {
    if (isPreview) return

    // we wait a bit on page load to start reading the notification stack
    setTimeout(() => {
      this.#popNextNotification()
    }, 500)
  }

  notificationTargetConnected(notification) {
    this.#popNextNotification()
  }

  resumeNotifications() {
    this.pause = false
    this.#popNextNotification()
  }

  pauseNotifications() {
    this.pause = true
  }

  // private

  #popNextNotification() {
    if (!this.firstUnreadNotification) return
    if (this.pause) return
    var notification = this.firstUnreadNotification

    setTimeout(() => {
      this.pause = true
      notification.click()
      notification.dataset.read = true
    }, this.popupDelay)
  }

  get unreadNotifications() {
    return this.notificationTargets.filter(notification => notification.dataset.read !== 'true')
  }

  get firstUnreadNotification() {
    return this.unreadNotifications[0]
  }

  #allowPause() {
    this.paused = false
    this.element.dataset.action = `pause:notifications@document->${this.identifier}#pauseNotifications resume:notifications@document->${this.identifier}#resumeNotifications`
  }
}
