import Controller from './application_controller'

export default class extends Controller {
  static values = {
    enlargeButtonText: String,
    enlargeButtonImage: String,
    reduceButtonText: String,
    reduceButtonImage: String
  }
  static targets = ['image']

  connect() {
    this.originalWidth = this.imageTarget.width
    this.originalHeight = this.imageTarget.height
    this.isEnlarged = false
    this.createButton(`<img src="${this.enlargeButtonImageValue}"/>${this.enlargeButtonTextValue}`)
  }

  createButton(html) {
    this.button = document.createElement('div')
    this.button.innerHTML = html
    this.button.style.display = 'none'
    this.button.classList.add(
      'absolute',
      'top-0',
      'right-0',
      'm-2',
      'py-2',
      'pl-2',
      'pr-3',
      'bg-blue',
      'bg-opacity-50',
      'text-white',
      'rounded-full',
      'cursor-pointer',
      'z-10'
    )
    this.button.addEventListener('click', event => {
      event.stopPropagation()
      this.toggleSize()
    })
    this.element.appendChild(this.button)
  }

  toggleSize() {
    if (this.isEnlarged) {
      this.#elementSetToSmall()

      this.button.innerHTML = `<img src="${this.enlargeButtonImageValue}"/>${this.enlargeButtonTextValue}`
      this.isEnlarged = false
    } else {
      this.#elementSetToLarge()

      this.button.innerHTML = `<img src="${this.reduceButtonImageValue}"/>${this.reduceButtonTextValue}`
      this.isEnlarged = true
    }
  }

  mouseOver() {
    this.button.style.display = 'block'
  }

  mouseOut() {
    this.button.style.display = 'none'
  }

  enlarge() {
    if (!this.isEnlarged) this.toggleSize()
  }

  reduce() {
    if (this.isEnlarged) this.toggleSize()
  }

  #elementSetToLarge() {
    this.imageTarget.classList.add('cursor-zoom-out')
    this.imageTarget.classList.remove('cursor-zoom-in')
    this.element.classList.add('cursor-zoom-out')
    this.element.classList.remove('cursor-zoom-in')
    this.element.classList.add('w-full')
    this.element.classList.remove('w-1/3')
  }

  #elementSetToSmall() {
    this.imageTarget.classList.add('cursor-zoom-in')
    this.imageTarget.classList.remove('cursor-zoom-out')
    this.element.classList.add('cursor-zoom-in')
    this.element.classList.remove('cursor-zoom-out')
    this.element.classList.add('w-1/3')
    this.element.classList.remove('w-full')
  }
}
