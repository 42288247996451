import { Controller } from '@hotwired/stimulus'
import { DragDrop } from './services/drag_drop'
import { EditableZone } from './services/editable_zone'
import { KeyboardHandler } from './services/keyboard_handler'
import { ParamsHandler } from './services/params_handler'
import { TextTransform } from './services/text_transform'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'sentence',
    'composable',
    'placeholder',
    'trash',
    'chunksInput',
    'word',
    'textZoneTemplate',
    'lineBreakTemplate',
    'editable',
    'submit',
    'textToSpeechContent'
  ]

  editableClasses = ['shadow', 'border', 'border-gray-lighter', 'bg-white', 'px-4', 'py-3']

  connect() {
    this.editableZone = new EditableZone(this)
    this.textTransform = new TextTransform(this)
    this.dragDrop = new DragDrop(this)
    this.keyboardHandler = new KeyboardHandler(this)
    this.paramsHandler = new ParamsHandler(this)

    this.dragDrop.init()
    this.editableZone.initZones()
    this.textTransform.capitalizeStartsOfSentences()
  }

  // actions
  submit(e) {
    const lastEditable = this.sentenceTarget.querySelector('.editable:last-of-type')
    if (lastEditable && lastEditable.textContent.trim().length > 0) {
      lastEditable.classList.add('word', 'composed-word', 'user-composed')
      lastEditable.classList.remove('editable')
      lastEditable.classList.add(...this.editableClasses)
      this.editableZone.addBefore(lastEditable)
      this.editableZone.addAfter(lastEditable)
    }

    this.paramsHandler.buildParamsFromSentence()
  }

  focus(e) {
    const clickedEditable = e.target.closest(
      '[data-writing--state--composable--component-target="editable"]'
    )

    if (clickedEditable && this.editableTargets.includes(clickedEditable)) {
      clickedEditable.focus()
    } else {
      const lastElement = this.sentenceTarget.lastElementChild
      if (!lastElement || !lastElement.classList.contains('editable')) {
        if (lastElement) {
          this.editableZone.addAfter(lastElement)
        } else {
          this.sentenceTarget.appendChild(this.editableZone.template)
        }
      }

      const lastEditable = this.sentenceTarget.querySelector('.editable:last-of-type')
      if (lastEditable) {
        lastEditable.focus()
        this.editableZone.setEndOfContenteditable(lastEditable)
      }
    }
  }

  filterKeypress(e) {
    this.keyboardHandler.handleKeypress(e)
  }

  handlePaste(e) {
    this.keyboardHandler.handlePaste(e)
  }
}
