import { Controller } from '@hotwired/stimulus'
import { useFieldCharacterCount } from '../mixin/useFieldCharacterCount'

export default class extends Controller {
  static targets = ['field', 'view']

  static values = {
    warningCharacterCount: Number,
    maxCharacterCount: Number
  }

  connect() {
    useFieldCharacterCount(this, {
      textWarningClass: 'text-orange',
      textDefaultClass: 'text-gray',
      warningCharacterCount: this.warningCharacterCount,
      maxCharacterCount: this.maxCharacterCount
    })
  }

  get warningCharacterCount() {
    return this.hasWarningCharacterCountValue
      ? this.warningCharacterCountValue
      : this.fieldTarget.getAttribute('maxlength') * 0.9 || 400
  }

  get maxCharacterCount() {
    return this.hasMaxCharacterCountValue
      ? this.maxCharacterCountValue
      : this.fieldTarget.getAttribute('maxlength') || 430
  }
}
