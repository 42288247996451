import { Controller } from '@hotwired/stimulus'
import { SpeechConfig, SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk'
import { getMetaValue } from '../../../javascript/utils/index.js'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['content', 'icon']
  static classes = ['play', 'pause']
  static values = {
    highlight: { type: Boolean, default: false },
    locale: { type: String, default: getMetaValue('locale') }
  }

  playingState = false

  // lifecycle methods

  connect() {
    this.application.lastTtsTokenAt ||= new Date(1970, 1, 1)
  }

  disconnect() {
    this.audioStop()
    this.synthesizer.close()
  }

  // actions

  togglePlay(e) {
    e.preventDefault()

    if (this.playing) {
      this.audioStop()
    } else {
      this.audioStart()
    }
  }

  audioStart = async () => {
    // stop all other players
    if (this.element.disabled) return
    if (this.playing) return
    if (this.text.length === 0) return

    this.playing = true
    const context = await this.audioContext()
    this.sibilings.forEach(sibiling => sibiling.audioStop())
    this.source = context.createBufferSource()
    this.source.connect(context.destination)
    this.source.onended = this.audioStop
    this.source.buffer = await this.decodedAudioData(context)
    this.source.start(0)
  }

  audioStop = async () => {
    if (!this.playing) return

    if (this.source?.buffer) {
      await this.source.stop()
      this.playing = false
    }
  }

  // private

  decodedAudioData(context) {
    return new Promise((resolve, reject) => {
      this.synthesizer.speakTextAsync(
        this.text,
        result => {
          context
            .decodeAudioData(result.audioData)
            .then(resolve)
            .catch(error => {
              this.playing = false
              reject(error)
            })
        },
        reject
      )
    })
  }

  async audioContext() {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    return new AudioContext()
  }

  // Getters and Setters

  get synthesizer() {
    const speechConfig = SpeechConfig.fromAuthorizationToken(this.token, 'westeurope')
    speechConfig.speechSynthesisLanguage = this.language
    const synthesizer = new SpeechSynthesizer(speechConfig, null) // null to disable the sound by default and play it manually
    synthesizer.wordBoundary = this.timecodesBuilder
    return synthesizer
  }

  get token() {
    const now = new Date()
    const elapsed = now - this.application.lastTtsTokenAt
    const tokenDurationInMs = 540_000
    if (this.application.ttsToken && elapsed < tokenDurationInMs) return this.application.ttsToken

    return post('/api/v1/azure/token')
      .then(response => response.json)
      .then(data => {
        if (data.created_at) this.application.lastTtsTokenAt = new Date(data.created_at)

        this.application.ttsToken = data.token
        return this.application.ttsToken
      })
  }

  set playing(state) {
    this.playingState = state
    this.playClass.split(',').forEach(playClass => {
      this.iconTarget.classList.toggle(playClass, state)
    })
    this.pauseClass.split(',').forEach(pauseClass => {
      this.iconTarget.classList.toggle(pauseClass, !state)
    })
  }

  get playing() {
    return this.playingState
  }

  get cues() {
    // convert times codes to cues FUTUR.....
    // let lastOffset = 0
    // return this.timecodes.map(({ audioOffset, text, textOffset, wordLength }) => {
    //   new VTTCue(lastOffset + audioOffset, endTime, text)
    //   return {
    //     startTime: audioOffset,
    //     endTime: audioOffset + wordLength,
    //     text,
    //     textOffset
    //   }
    // })
  }

  get sibilings() {
    return this.application.controllers.filter(
      controller => controller.identifier == this.identifier && controller != this
    )
  }

  get language() {
    return this.localeValue == 'fr' ? 'fr-FR' : 'en-US'
  }

  get text() {
    const text = this.element.dataset.text || this.contentTarget.innerText
    return text.trim()
  }

  // private
  timecodesBuilder = (_synthesizer, { audioOffset, text, textOffset, wordLength }) => {
    this.timecodes.push({ audioOffset, text, textOffset, wordLength })
  }
}
