import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.shouldOpen) {
      $(this.element).modal('show')
    }
    $(this.element).on('hidden.bs.modal', this.reloadOnScroll)
  }

  disconnect() {
    $(this.element).modal('hide')
  }

  reloadPage(e) {
    window.location.reload()
  }

  reloadOnScroll = () => {
    if (this.shouldReloadOnClose) {
      window.location = window.location.pathname
    }
  }

  get shouldOpen() {
    return this.data.has('open') && this.data.get('open') === 'true'
  }

  get shouldReloadOnClose() {
    return this.data.has('shouldReloadOnClose') && this.data.get('shouldReloadOnClose') === 'true'
  }
}
