import { Controller } from '@hotwired/stimulus'
import SweetScroll from 'sweet-scroll'

export default class extends Controller {
  static targets = ['section', 'continueButton']

  #scroller

  // Lifecycle
  connect() {
    this.#initScroller()
    this.#focusNextButton()
  }

  disconnect() {
    this.#scroller.destroy()
  }

  // Actions
  nextSection({ params: { index } }) {
    this.#hideCurrentNextButton(index)
    this.#displayNextSection(index)

    try {
      this.#scroller.toElement(this.sectionTargets[index + 1])
    } catch (_) {
      // in some cases the scroller fails (missing polyfill)
      return false
    }
    this.#focusNextButton(index + 1)
  }

  // Private

  #focusNextButton(index = 0) {
    requestAnimationFrame(() => {
      this.continueButtonTargets[index]?.querySelector('button')?.focus()
    })
  }

  #initScroller() {
    this.#scroller = new SweetScroll({
      duration: 1500,
      header: '.navbar',
      easing: 'easeOutQuint'
    })
  }

  #hideCurrentNextButton(index) {
    this.continueButtonTargets[index].classList.add('hidden')
  }

  #displayNextSection(index) {
    const section = this.sectionTargets[index + 1]

    section.classList.remove('hidden')
    requestAnimationFrame(() => {
      section.classList.add('opacity-100')
    })

    if (index === this.lastSectionIndex - 1) {
      this.dispatch('progressive-display:complete', { prefix: false })
    }
  }

  get lastSectionIndex() {
    return this.sectionTargets.length - 1
  }
}
