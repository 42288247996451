import Controller from './application_controller'

export default class extends Controller {
  connect() {
    import(/* webpackChunkName: "sweet-scroll" */ 'sweet-scroll')
      .then(module => {
        this.SweetScroll = module.default
        this.initScroller()
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  initScroller = () => {
    this.scroller = new this.SweetScroll({
      duration: 1000,
      offset: 350,
      header: '.navbar',
      easing: 'easeOutQuint',
      trigger: '[data-scroll]'
    })
  }

  disconnect() {
    this.scroller.destroy()
  }
}
