const defaultOptions = {
  events: ['input']
}

export const useFieldAutoResize = (controller, options = {}) => {
  const { events } = { ...defaultOptions, ...options }

  const fieldElement =
    options.fieldElement ||
    (controller.hasFieldTarget && controller.fieldTarget) ||
    controller.element

  const scrollRootElement = options.scrollRootElement || document.body

  const onEvent = () => {
    computeAndApplyResize()
  }

  const controllerDisconnect = controller.disconnect
  const controllerObserveFieldAutoResize = controller._observeFieldAutoResize
  const controllerUnObserveFieldAutoResize = controller._unObserveFieldAutoResize

  const computeAndApplyResize = () => {
    const scrollLeft =
      window.pageXOffset ||
      (document.documentElement || document.body.parentNode || document.body).scrollLeft
    const scrollDelta = fieldElement.scrollHeight - controller.previousScrollHeight
    const scrollTop = scrollRootElement.scrollTop + scrollDelta

    controller.previousScrollHeight = fieldElement.scrollHeight
    fieldElement.style.height = 'auto'
    fieldElement.style.height = fieldElement.scrollHeight + 'px'

    if (supportsNativeSmoothScroll) {
      // scroll is not implemented in Microsoft Edge
      scrollRootElement.scrollTo(scrollLeft, scrollTop)
    }
  }

  Object.assign(controller, {
    _observeFieldAutoResize() {
      events.forEach(event => {
        fieldElement.addEventListener(event, onEvent)
      })
      controllerObserveFieldAutoResize && controllerObserveFieldAutoResize()
      computeAndApplyResize()
      controller.previousScrollHeight = fieldElement.scrollHeight
      fieldElement.style.overflowY = 'hidden'
    },
    _unObserveFieldAutoResize() {
      events.forEach(event => {
        fieldElement.removeEventListener(event, onEvent)
      })
      controllerUnObserveFieldAutoResize && controllerUnObserveFieldAutoResize()
    },
    disconnect() {
      this._unObserveFieldAutoResize()
      controllerDisconnect()
    },
    computeAndApplyResize
  })
  requestAnimationFrame(() => {
    controller._observeFieldAutoResize()
  })
}

const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style
