export function timingByEnv(value, divider = 100) {
  return isTestEnvironement ? value / divider : value
}

export function getMetaValue(name, defaultValue = '') {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return (element && autoCasting(element.getAttribute('content'))) || defaultValue
}

export const isMobile = getMetaValue('mobile')

export const isTestEnvironement = getMetaValue('environment') === 'test'

export const isBetaTester = getMetaValue('tester')

export const isPreview = document.documentElement.hasAttribute('data-turbo-preview')

function autoCasting(value) {
  try {
    return JSON.parse(value)
  } catch (_) {
    return value
  }
}
