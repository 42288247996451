import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cyan', 'magenta', 'yellow', 'black', 'preview', 'color']

  connect() {
    this.updateColor()
    this.previewTarget.style.width = '100px'
    this.previewTarget.style.height = '100px'
  }
  updateColor() {
    let c = parseFloat(this.cyanTarget.value) / 100
    let m = parseFloat(this.magentaTarget.value) / 100
    let y = parseFloat(this.yellowTarget.value) / 100
    let n = parseFloat(this.blackTarget.value) / 100

    let r = Math.round(255 * (1 - c) * (1 - n))
    let g = Math.round(255 * (1 - m) * (1 - n))
    let b = Math.round(255 * (1 - y) * (1 - n))

    let color = 'rgb(' + r + ', ' + g + ', ' + b + ')'
    this.previewTarget.style.backgroundColor = color
    this.colorTarget.value = `${this.cyanTarget.value}, ${this.magentaTarget.value}, ${this.yellowTarget.value}, ${this.blackTarget.value}`
  }
}
