import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['view']

  start(e) {
    const { removeAfter } = e.detail
    this.viewTarget.classList.add('spinner--busy')

    // taken from https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`
    if (removeAfter) {
      setTimeout(() => {
        this.stop()
      }, removeAfter)
    }
  }

  stop = () => {
    this.viewTarget.classList.remove('spinner--busy')
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}
