import { Controller } from '@hotwired/stimulus'
import { useDispatch } from 'stimulus-use'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['letter', 'word']
  static values = { tips: Array, gameId: String, completed: Boolean, attemptsUrl: String }
  tipIndex = 0
  hasPlayed = false

  initialize() {
    useDispatch(this, { eventPrefix: false })
  }

  // private
  play = e => {
    this.hasPlayed = true
    this.dispatch('game:played')
    this.dispatch(`${this.gameIdValue}:played`)
    this.dispatch('play:sound', { randomSounds: ['kick1', 'kick2'] })
  }

  success = e => {
    this.element.classList.remove('error')
    this.dispatch('play:sound', { sound: 'success' })
    this.dispatch('game:success')
    this.dispatch(`${this.gameIdValue}:success`)
  }

  error = e => {
    this.element.classList.add('error')
    patch(this.attemptsUrlValue)
    this.dispatch('game:error')
    this.dispatch(`${this.gameIdValue}:error`)
    this.dispatch('play:sound', { sound: 'failure' })
    this.dispatch(`tip:${this.gameIdValue}:display`, { text: this.nextTip })
  }

  clear = e => {
    this.element.classList.remove('error')
  }

  get nextTip() {
    const tip = this.tipsValue[this.tipIndex]
    if (this.tipIndex < this.tipsValue.length - 1) {
      this.tipIndex++
    }
    return tip
  }
}
