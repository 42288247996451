import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['errorMessage']

  inform(event) {
    this.errorMessageTarget.innerHTML = html(event)
  }

  thanks(event) {
    this.element.outerHTML = html(event)
    this.subscribeToNewsletterEvent()
  }

  subscribeToNewsletterEvent() {
    window.dataLayer.push({ event: 'newsletter_sub', event_action: 'inscription' })
  }
}

function html(event) {
  const [data, status, xhr] = event.detail
  return xhr.response
}
