import { Controller } from '@hotwired/stimulus'
import copy from 'copy-to-clipboard'
// to replace by clipboard controller
export default class extends Controller {
  static targets = ['value', 'notify']

  connect() {
    this.notifyTarget.style.zIndex = '-200'
  }

  execute(e) {
    copy(this.valueTarget.value || this.valueTarget.textContent)
    this.copiedMessageAnimation(e.target)
  }

  copiedMessageAnimation() {
    this.notifyTarget.style.opacity = '1'
    this.notifyTarget.style.zIndex = '200'
    setTimeout(() => {
      this.notifyTarget.style.opacity = '0'
      this.notifyTarget.style.zIndex = '-200'
    }, 1000)
  }

  copiedMessageAnimatio2n(target) {
    target.textContent = 'Copié!'
    setTimeout(() => {
      target.textContent = 'Copier'
    }, 1000)
  }
}
