import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { x: Number, y: Number }

  connect() {
    import(/* webpackChunkName: "interactjs" */ 'interactjs').then(Module => {
      this.interact = Module.default
      this.initializeInteract()
    })
  }

  // private

  initializeInteract() {
    this.interact(this.element).draggable({
      inertia: true,
      modifiers: [
        this.interact.modifiers.restrictRect({
          restriction: 'html',
          endOnly: true
        })
      ],
      autoScroll: true,
      listeners: {
        move: this.handleMove
      }
    })
  }

  handleMove = event => {
    this.xValue += event.dx
    this.yValue += event.dy
    // translate the element
    this.element.style.transform = 'translate(' + this.xValue + 'px, ' + this.yValue + 'px)'
  }
}
