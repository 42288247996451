import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="drawer"
// for now it only works with a bottom drawer!
export default class extends Controller {
  static targets = ['drawer', 'toggle', 'backdrop']
  static values = { open: Boolean, toggleSelector: String }

  // lifecycles
  initialize() {
    useClickOutside(this, { element: this.drawerTarget })
    this.#listenForToogleClick()
  }

  disconnect() {
    this.#tearDownToogleClickListeners()
  }

  // actions
  toggle() {
    this.openValue = !this.openValue
  }

  // callbacks
  openValueChanged() {
    this.backdropTarget.classList.toggle('hidden', !this.openValue)
    this.drawerTarget.classList.toggle('translate-y-full', !this.openValue)
    this.drawerTarget.classList.toggle('translate-y-0', this.openValue)
    if (this.openValue) {
      this.#disableScroll()
    } else {
      this.#enableScroll()
    }
  }

  clickOutside() {
    this.openValue = false
  }

  // private
  #disableScroll() {
    // Store the current scroll position
    this.scrollPosition = window.scrollY || document.documentElement.scrollTop

    document.body.classList.add('no-scroll')

    // Apply the stored position as a top style to body
    document.body.style.top = `-${this.scrollPosition}px`
  }

  #enableScroll() {
    if (this.scrollPosition === undefined) return

    document.body.classList.remove('no-scroll')
    // Remove the top style from body
    document.body.style.top = ''

    // Restore the scroll position
    window.scrollTo(0, this.scrollPosition)
  }

  #listenForToogleClick() {
    if (!this.toggleSelectorValue) return

    document.querySelectorAll(this.toggleSelectorValue).forEach(el => {
      el.addEventListener('click', this.toggle.bind(this))
    })
  }

  #tearDownToogleClickListeners() {
    if (!this.toggleSelectorValue) return

    document.querySelectorAll(this.toggleSelectorValue).forEach(el => {
      el.removeEventListener('click', this.toggle.bind(this))
    })
  }
}
