import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = ['form', 'emailField']

  connect() {
    useMutation(this, { childList: true, subtree: true })
  }

  mutate() {
    this.formTarget.classList.toggle('hidden', this.emailFields.length === 0)
  }

  get emailFields() {
    return this.emailFieldTargets.filter(target => target.value !== '')
  }
}
