import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['openButton', 'closeButton']

  connect() {
    if (!this.element.requestFullscreen) {
      this.element.remove()
    }
  }

  toggle() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
      this.closeButtonTarget.classList.remove('hidden')
      this.openButtonTarget.classList.add('hidden')
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
      this.closeButtonTarget.classList.add('hidden')
      this.openButtonTarget.classList.remove('hidden')
    }
  }
}
