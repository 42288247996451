import { Extension, textInputRule } from '@tiptap/core'

const emDash = textInputRule({
  find: /--$/,
  replace: '—'
})

const ellipsis = textInputRule({
  find: /\.\.\.$/,
  replace: '…'
})

const openDoubleQuote = textInputRule({
  find: /(?:^|[\s{[(<'"\u2018\u201C])(")$/,
  replace: '« '
})

const closeDoubleQuote = textInputRule({
  find: /"$/,
  replace: ' »'
})

const singleQuote = textInputRule({
  find: /[a-zA-Z](')[a-zA-Z]$/,
  replace: '’'
})

const laquo = textInputRule({
  find: /<<$/,
  replace: '« '
})

const raquo = textInputRule({
  find: />>$/,
  replace: ' »'
})

const Typography = Extension.create({
  name: 'typography',

  addInputRules() {
    const rules = []

    if (this.options.emDash !== false) {
      rules.push(emDash)
    }

    if (this.options.ellipsis !== false) {
      rules.push(ellipsis)
    }

    if (this.options.openDoubleQuote !== false) {
      rules.push(openDoubleQuote)
    }

    if (this.options.closeDoubleQuote !== false) {
      rules.push(closeDoubleQuote)
    }

    if (this.options.singleQuote !== false) {
      rules.push(singleQuote)
    }

    if (this.options.laquo !== false) {
      rules.push(laquo)
    }

    if (this.options.raquo !== false) {
      rules.push(raquo)
    }

    return rules
  }
})

export default Typography
