import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toast']
  static values = {
    opening: {
      from: ['translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2', 'h-0'],
      transition: ['transform', 'transition', 'ease-out', 'duration-300'],
      to: ['translate-y-0', 'opacity-100', 'sm:translate-x-0']
    },
    closing: {
      from: ['opacity-100'],
      transition: ['transition', 'ease-in', 'duration-300'],
      to: ['opacity-0']
    }
  }

  connect() {
    this.#open()
  }

  close() {
    if (!this.hasToastTarget) return

    this.toastTarget.classList.remove(...this.closingValue.from)
    this.toastTarget.classList.add(...this.closingValue.transition)
    this.toastTarget.classList.add(...this.closingValue.to)
    setTimeout(() => {
      this.toastTarget.remove()
    }, 500)
  }

  #open() {
    setTimeout(() => {
      this.toastTarget.classList.remove(...this.openingValue.from)
      this.toastTarget.classList.add(...this.openingValue.transition)
      this.toastTarget.classList.add(...this.openingValue.to)
      if (this.toastTarget.dataset.autoClose === 'true') {
        setTimeout(() => {
          this.close()
        }, parseInt(this.toastTarget.dataset.duration))
      }
    }, parseInt(this.toastTarget.dataset.delay))
  }
}
