import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['toggle', 'menu']
  static values = { open: Boolean }

  connect() {
    // will we have Bootstrap JS enable we force the dispose to fix a bug where
    // the dropdown can sometime fail to open
    $(this.toggleTarget).dropdown('dispose')

    useClickOutside(this)
    this.toggleTarget.addEventListener('click', this.toggle.bind(this), false)
  }

  disconnect() {
    this.toggleTarget.removeEventListener('click', this.toggle)
    this.openValue = false
  }

  // callbacks
  clickOutside(event) {
    this.openValue = false
  }

  openValueChanged() {
    this.render()
  }

  // private

  toggle() {
    this.openValue = !this.openValue
  }

  render() {
    this.element.classList.toggle('show', this.openValue)
    if (this.hasMenuTarget) {
      this.menuTarget.classList.toggle('show', this.openValue)
    }
    this.toggleTarget.ariaExpanded = this.openValue
  }
}
