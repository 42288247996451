import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['existingAddressValue', 'newAddress', 'selectedAddress', 'submitButton']

  showSelectedAddress() {}

  hideNewAddress() {
    if (this.existingAddressValueTarget.value.length >= 1) {
      let address = this.address(this.existingAddressValueTarget.value)
      this.newAddressTarget.style.display = 'none'
      this.selectedAddressTarget.style.display = 'block'
      this.submitButtonTarget.dataset.shouldBeDisable = 'false'
    } else {
      this.newAddressTarget.style.display = 'block'
      this.selectedAddressTarget.setAttribute('style', 'display: none !important')
      this.submitButtonTarget.removeAttribute('data-should-be-disable')
    }
  }

  address(address_id) {
    $.get('/api/v1/addresses/' + address_id, function (data, status) {
      document.getElementById('selectedAddressFirstName').innerText = data.recipient_first_name
      document.getElementById('selectedAddressLastName').innerText = data.recipient_last_name
      document.getElementById('selectedAddressStreet').innerText = data.street
      if (data.additional_details != null) {
        document.getElementById('selectedAddressAdditionalDetails').innerText =
          data.additional_details
      } else {
        document.getElementById('selectedAddressAdditionalDetails').style.display = 'none'
      }
      document.getElementById('selectedAddressZip').innerText = data.zip
      document.getElementById('selectedAddressCity').innerText = data.city
    })
  }
}
