import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { defaultBack: String }

  back() {
    if (window.history.length > 1) {
      window.history.back()
    } else {
      window.location = this.defaultBackValue
    }
  }
}
