import Controller from './application_controller'
import { getMetaValue } from '../utils'

export default class extends Controller {
  static get shouldLoad() {
    return getMetaValue('google_tag_manager')
  }

  static values = { eventContent: Object }

  connect() {
    if (this.isPreview) return

    // this is to handle events send from the backend
    setTimeout(() => {
      if (window.dataLayer && this.hasEventContentValue) {
        dataLayer.push(this.eventContentValue)
      }
    }, 500)
  }

  disconnect() {
    // just make sure the flash is not persisted during a Turbo navigation
    this.element.remove
  }
}
