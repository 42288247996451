import anime from 'animejs'

export const swing = target => {
  return anime
    .timeline({
      targets: target, //   value: 10, // translateX: {
      duration: 300,
      direction: 'alternate',
      easing: 'easeInOutQuad',
      autoplay: false
    })
    .add({ rotate: 8, transformOrigin: '10px 50px 0' })
    .add({
      rotate: -8,
      transformOrigin: '30px 50px 0'
    })
}

export const inflate = target => {
  return anime({
    targets: target, //   value: 10, // translateX: {
    scale: 1.5,
    easing: 'easeInOutSine',
    duration: 200,
    elasticity: 50,
    direction: 'alternate',
    autoplay: false
  })
}

export const smallJump = target => {
  return anime({
    targets: target,
    translateY: {
      value: -3 + 'rem',
      duration: 200
    },
    scale: 2,
    duration: 350,
    direction: 'alternate',
    easing: 'easeInCubic',
    autoplay: false
  })
}

export const bigJump = target => {
  return anime({
    targets: target, //   value: 10, // translateX: {
    translateY: -5,
    scale: 3,
    easing: 'easeInOutSine',
    duration: 350,
    elasticity: 100,
    direction: 'alternate',
    autoplay: false
  })
}

export const pirouette = target => {
  return anime({
    targets: target, //   value: 10, // translateX: {
    rotate: '1turn',
    easing: 'easeInOutSine',
    duration: 500,
    elasticity: 100,
    // direction: "alternate",
    autoplay: false
  })
}
