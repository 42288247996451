import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="text-field"
export default class extends Controller {
  static targets = ['input', 'errorMessage']
  static classes = ['invalid']

  initialize() {
    this.cleared = !this.hasErrorMessageTarget
  }

  connect() {
    this.inputTarget.addEventListener('input', this.resetError.bind(this))
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.resetError.bind(this))
  }

  resetError() {
    if (this.cleared) {
      return
    }

    this.errorMessageTarget.classList.add('hidden')
    this.inputTarget.classList.remove(...this.invalidClasses)
    this.cleared = true
  }
}
