import { Controller } from '@hotwired/stimulus'
import copy from 'copy-to-clipboard'

export default class extends Controller {
  static targets = ['value', 'notification']

  execute(e) {
    copy(this.valueTarget.value || this.valueTarget.textContent)
    this.copiedMessageAnimation(e.target)
  }

  copiedMessageAnimation() {
    this.notificationTarget.classList.add('copied')
    setTimeout(() => {
      this.notificationTarget.classList.remove('copied')
    }, 1000)
  }
}
