import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import { diffWords } from 'diff'
import sanitizeHtml from 'sanitize-html'

import { Editor } from '@tiptap/core'
import Document from '@tiptap/extension-document'

import Bold from '@tiptap/extension-bold'
import { Color } from '@tiptap/extension-color'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { getMetaValue } from '../utils'

// custom extensions and marks
import Diff from '../models/tip_tap/diff_extension'
import Highlight from '../models/tip_tap/highlight_extension'
import Typography from '../models/tip_tap/typography'

export default class extends Controller {
  static targets = [
    'editor',
    'contentField',
    'error',
    'textToSpeechContent',
    'toggleBold',
    'toggleItalic',
    'toggleUnderline',
    'toggleHighlight',
    'highlightColors',
    'highlightsInput',
    'highlighted',
    'rightButtons',
    'loader'
  ]
  static values = {
    content: String,
    analysisUrl: String,
    explanationUrl: String,
    editable: { type: Boolean, default: true },
    typography: { type: Boolean, default: false },
    richText: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    attributes: { type: Object, default: {} },
    boldState: { type: Boolean, default: false },
    italicState: { type: Boolean, default: false },
    underlineState: { type: Boolean, default: false },
    highlightState: { type: String, default: '' },
    highlightMode: { type: Boolean, default: false },
    resourceGid: { type: String, default: '' },
    automatedCorrection: { type: String, default: '' },
    busy: { type: Boolean, default: false },
    originalTextForDiff: { type: String, default: '' },
    richTextAllowedTags: { type: Array, default: [] },
    preventPaste: { type: Boolean, default: false }
  }
  static outlets = ['form--text-editor--word-count--component', 'chat']
  static classes = ['field']

  initialize() {
    this.element.setAttribute('data-turbo-permanent', true)
  }

  connect() {
    if (this.isPreview) return

    const defaultClasses = 'body--lg focus:!outline-none h-full pb-12'

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: this.extensions,
      content: this.#writingContentToHTML,
      editable: this.editableValue,
      editorProps: {
        handlePaste: () => {
          return this.preventPasteValue
        },
        attributes: {
          class: defaultClasses,
          ...this.attributesValue
        },
        handleKeyDown: this.#handleKeyPress.bind(this)
      },
      parseOptions: {
        preserveWhitespace: 'full'
      }
    })

    this.editor.on('update', () => this.#handleContentUpdate())
    // This is to update the rich text button status according to the text selection
    this.editor.on('selectionUpdate', () => {
      if (this.highlightModeValue) {
        this.editor.commands.setHighlight({ color: this.highlightStateValue })
        this.collectHighlightsData()
      }
      this.checkToggleButtonStatus()
    })

    this.editor.on('create', ({ editor }) => {
      // let's add this ready attribute so that we can target it in the system test to prevent flaky test
      editor.view.dom.setAttribute('ready', true)
    })

    this.#updateTextToSpeechContent()
    this.#updateWordCounter()
  }

  disconnect() {
    this.element.removeAttribute('data-turbo-permanent')
  }

  // actions

  toggleBold() {
    if (this.boldStateValue) {
      this.editor.commands.unsetBold()
    } else {
      this.editor.commands.setBold()
    }
    this.boldStateValue = !this.boldStateValue
    this.editor.commands.focus()
  }

  toggleItalic() {
    if (this.italicStateValue) {
      this.editor.commands.unsetItalic()
    } else {
      this.editor.commands.setItalic()
    }
    this.italicStateValue = !this.italicStateValue
    this.editor.commands.focus()
  }

  toggleUnderline() {
    if (this.underlineStateValue) {
      this.editor.commands.unsetUnderline()
    } else {
      this.editor.commands.setUnderline()
    }
    this.underlineStateValue = !this.underlineStateValue
    this.editor.commands.focus()
  }

  undo() {
    this.editor.commands.undo()
  }

  redo() {
    this.editor.commands.redo()
  }

  toggleHighlightColors() {
    this.highlightColorsTarget.classList.toggle('hidden')
    this.rightButtonsTarget.classList.toggle('hidden')
  }

  highlightModeOn(e) {
    this.highlightModeValue = true
    let color = e.currentTarget.dataset.color
    this.element.classList.add(`highlight-mode-${color}`)
    this.highlightStateValue = this.highlightModeValue ? color : ''
  }

  highlightModeOff() {
    this.highlightModeValue = false
    const prefix = 'highlight-mode-'
    const classes = this.element.className.split(/\s+/)

    classes.forEach(className => {
      if (className.startsWith(prefix)) {
        this.element.classList.remove(className)
      }
    })
  }

  toggleHighlight(e) {
    // return if it was a double click
    if (e.detail === 2) return
    // disable turbo stabilo if it was enabled
    if ((e.detail === 1 && this.highlightModeValue) || e.key == 'Escape')
      return this.highlightModeOff()
    // enable turbo stabilo if nothing is selected
    if (e.detail === 1 && this.editor.state.selection.anchor == this.editor.state.selection.head)
      return this.highlightModeOn(e)

    let color = e.currentTarget.dataset.color

    if (e.currentTarget.classList.contains('active')) {
      this.editor.commands.unsetHighlight()
      this.highlightStateValue = ''
    } else {
      this.editor.commands.setHighlight({ color: color })
      this.highlightStateValue = color
    }
    this.collectHighlightsData()
    this.editor.commands.focus()
  }

  autoCorrect(e) {
    this.automatedCorrectionValue = ''
    this.busyValue = true

    const textToCorrect = this.richTextWithParagraphs
    this.highlightsInputTarget.value = this.highlightsInputTarget.value = JSON.stringify({})
    post('/open_ai/corrections', {
      body: {
        text: textToCorrect,
        target_id: this.element.id,
        resource_gid: this.resourceGidValue.toString()
      },
      headers: {
        Accept: 'application/json'
      }
    })
  }

  automatedCorrectionValueChanged(correctedText) {
    if (!correctedText) return
    // here we receive the call back from the background job that update the attribute

    const formatedText = correctedText.replace(/(\r\n|\r|\n)/g, '</p><p>')
    this.editor.commands.setContent(formatedText, { emitUpdate: true })
  }

  checkToggleButtonStatus() {
    this.boldStateValue = this.editor.isActive('bold')
    this.italicStateValue = this.editor.isActive('italic')
    this.underlineStateValue = this.editor.isActive('underline')
    this.highlightStateValue = this.editor.getAttributes('highlight')?.color
  }

  // callback

  boldStateValueChanged(value) {
    if (this.hasToggleBoldTarget) {
      this.toggleBoldTargets.forEach(toggleBold => {
        toggleBold.classList.toggle('active', value)
      })
    }
  }

  italicStateValueChanged(value) {
    if (this.hasToggleItalicTarget) {
      this.toggleItalicTargets.forEach(toggleItalic => {
        toggleItalic.classList.toggle('active', value)
      })
    }
  }

  underlineStateValueChanged(value) {
    // I don't understand how it's possible to ahve multiple toggleUnderlineTargets
    if (this.hasToggleUnderlineTarget) {
      this.toggleUnderlineTargets.forEach(toggleUnderline => {
        toggleUnderline.classList.toggle('active', value)
      })
    }
  }

  highlightStateValueChanged(value) {
    if (this.hasToggleHighlightTarget) {
      this.toggleHighlightTargets.forEach(toggleHighlight => {
        toggleHighlight.classList.toggle('active', toggleHighlight.dataset.color == value)
      })
    }
  }

  busyValueChanged(value) {
    if (!this.hasLoaderTarget) return

    if (value) {
      this.loaderTarget.classList.remove('invisible')
    } else {
      this.loaderTarget.classList.add('invisible')
    }
  }

  chatOutletConnected(outlet) {
    outlet.updateText(this.plainText)
  }

  collectHighlightsData() {
    let highlights = {}
    this.highlightedTargets.forEach(highlighted => {
      const color = highlighted.getAttribute('data-color')
      if (highlights[color]) {
        highlights[color] += 1
      } else {
        highlights[color] = 1
      }
    })

    this.highlightsInputTarget.value = JSON.stringify(highlights)
  }

  // private

  #updateTextToSpeechContent() {
    if (this.hasTextToSpeechContentTarget)
      this.textToSpeechContentTarget.dataset.text = this.plainText
  }

  // handlers

  #handleContentUpdate() {
    this.contentFieldTarget.value = this.text
    this.#updateTextToSpeechContent()
    this.dispatch('content:changed', { detail: { text: this.plainText }, prefix: false })
    this.#updateWordCounter()
    this.hasChatOutlet && this.chatOutlet.updateText(this.plainText)
  }

  #handleKeyPress(_view, event) {
    this.editor.commands.unsetHighlightForLastWord(event.key)
    return false // Do not stop other handlers
  }

  #updateWordCounter() {
    if (this.hasFormTextEditorWordCountComponentOutlet) {
      this.formTextEditorWordCountComponentOutlet.textValue = this.plainText
    }
  }

  // getters

  get extensions() {
    const placeHolder = Placeholder.configure({
      placeholder: this.placeholderValue,
      showOnlyWhenEditable: false,
      showOnlyCurrent: false,
      includeChildren: true
    })

    const highlight = Highlight.configure({
      HTMLAttributes: {
        'data-tip-tap-target': 'highlighted'
      },
      multicolor: true,
      isKid: !!getMetaValue('current_kid')
    })
    let extensions = [placeHolder, Document, Text, History, Paragraph, TextStyle, highlight]

    const diff = Diff.configure({
      initialText: this.originalTextForDiffValue
    })

    extensions.push(diff)

    if (this.richTextValue) {
      extensions = [Bold, Italic, Underline, ...extensions]
    }

    if (this.typographyValue) {
      extensions.push(Typography)
    }
    return extensions
  }

  get #writingContentToHTML() {
    const rawContent = this.contentValue || this.contentFieldTarget.value
    if (rawContent === '') return ''

    const content = rawContent.replace(/(\r\n|\r|\n)/g, '</p><p>')
    return `<p>${content}</p>`
  }

  get text() {
    return this.editor
      .getHTML()
      .replace(/<\/p><p>/g, '\n') // replace empty HTML paragraphs with empty line
      .replace(/<\/?p>/g, '') // remove HTML paragraph tags
  }

  get richTextWithParagraphs() {
    return sanitizeHtml(this.editor.getHTML(), {
      allowedTags: ['p', ...this.richTextAllowedTagsValue]
    })
  }

  get plainText() {
    var tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = this.editor.getHTML().replace('</p><p>', '</p>\n<p>')

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || ''
  }

  get errors() {
    try {
      return JSON.parse(this.errorTarget.dataset.errors)
    } catch (error) {
      return []
    }
  }
}

// c’est une forterese sinistre qui se dresse devant eux. Des créature vêtues de noir en garde l’entrée. Il semble impossible de s’y aventurer sens devoir d’abord les affronter. alix est terrifié et la licorn paraît tout aussi inquiète. Il est évident qu'elles n’y arriveront pas seules, qu'elles ont besoin de soutien. peut-être qu’une diversion lur permettrai de se faufilé discrètement à travers les remparts…
