import Controller from './application_controller'

const defaultVolume = 0.5

export default class extends Controller {
  static values = { soundList: Object, soundBase64List: Object }

  connect() {
    this.initializeSounds()
  }

  play(e) {
    const randomSounds = e.detail.randomSounds
    let randomSound
    if (randomSounds) {
      randomSound = randomSounds[Math.floor(Math.random() * randomSounds.length)]
    }
    const sound = randomSound ?? e.currentTarget.dataset.sound ?? e.detail.sound
    const volume = e.currentTarget.dataset.volume ?? e.detail?.volume ?? defaultVolume

    if (this.soundKeys.indexOf(sound) >= 0) {
      const soundPlayer = this[`${sound}Sound`]
      if (volume) {
        soundPlayer.volume = volume
      }
      soundPlayer.play().catch(err => console.log(err))
    }
  }

  disconnect() {
    try {
      this.unloadSounds()
    } catch (error) {
      this.handleError(error)
    }
  }

  // private

  initializeSounds() {
    this.soundKeys.forEach(sound => {
      const audio = new Audio(this.soundList[sound])
      audio.volume = defaultVolume
      this[`${sound}Sound`] = audio
    })
  }

  unloadSounds() {
    this.soundKeys.forEach(sound => {
      const soundPlayer = this[`${sound}Sound`]
      soundPlayer.remove()
      soundPlayer.srcObject = null
    })
  }

  get soundKeys() {
    return Object.keys(this.soundList)
  }

  get soundList() {
    return this.hasSoundBase64ListValue ? this.soundBase64ListValue : this.soundListValue
  }
}
