import Controller from './application_controller'
import { useMutation, useIntersection } from 'stimulus-use'

// this controller is used in the iframe template to send the height of the iframe to the parent window
// this is used to set the height of the iframe in the parent window
//
// This is the typical code to put on webflow
//
// <script>
//   window.addEventListener("message", function (e) {
//     var height = e.data.height;
//     var source = e.data.source;
//     var src = e.data.src;
//
//     var iframeSrc = document.querySelector(`[src="${src}"]`);
//     var iframeHeight = iframeSrc.style.height;
//
//     if (iframeSrc && height > 0 && iframeHeight !== `${height}px`) {
//       iframeSrc.style.height = height + "px";
//     }
//   });
// </script>

export default class extends Controller {
  connect() {
    useMutation(this, { characterData: true, childList: true, subtree: true })
    useIntersection(this)

    requestAnimationFrame(() => {
      this.#sendMessageToParent('connect')
    })
  }

  // callback for mutation observer

  appear() {
    requestAnimationFrame(() => {
      this.#sendMessageToParent('connect')
    })
  }

  mutate() {
    this.#sendMessageToParent('mutate')
  }

  // private

  #sendMessageToParent(source) {
    const height = document.body.scrollHeight
    // we need to send the src of the iframe to the parent window because they are several iframe on the same page
    // and we need to know which iframe is sending the message in order to update the height of the right iframe
    const src = window.location.href

    if (window.parent != window && height > 0) {
      window.parent.postMessage({ height, source, src }, '*')
    }
  }
}
