import Controller from './application_controller'

export default class extends Controller {
  static targets = [
    'correctionEditor',
    'reviewEditor',
    'actionsContainer',
    'reviewTextArea',
    'review',
    'reviewText'
  ]

  toggleReviewEditor(e) {
    e.preventDefault()
    this.actionsContainerTarget.classList.remove('flex')
    this.actionsContainerTarget.classList.add('!hidden')
    this.reviewEditorTarget.classList.remove('!hidden')
    this.correctionEditorTarget.classList.add('!hidden')
  }

  displayReview(e) {
    this.reviewEditorTarget.classList.add('!hidden')
    this.reviewTarget.classList.remove('!hidden')
    this.reviewTextTarget.innerHTML = this.reviewTextAreaTarget.value
  }

  removeActionsContainer(e) {
    if (this.hasActionsContainerTarget) {
      this.actionsContainerTarget.remove()
    }
  }

  edit() {
    this.reviewTarget.classList.add('!hidden')
    this.reviewEditorTarget.classList.remove('!hidden')
  }
}
