import { getMetaValue } from '../utils'
import * as Sentry from '@sentry/browser'
window.Sentry = Sentry

export function enableSentry() {
  const shouldEnableSentry = getMetaValue('sentry')

  if (shouldEnableSentry) {
    Sentry.init({
      dsn: getMetaValue('sentry'),
      ignoreErrors: [
        'NetworkError when attempting to fetch resource.',
        'AbortError: The operation was aborted.',
        'NetworkError when attempting to fetch resource.',
        'TypeError: Type error',
        'TypeError: Failed to fetch',
        'AbortError: Fetch is aborted',
        'TypeError: TypeError: Load failed'
      ]
    })

    Sentry.configureScope(function (scope) {
      scope.setUser({ id: () => getMetaValue('user_id') })
    })
  }
}
