import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'button']
  static values = {
    openText: String,
    closedText: String
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden')
    this.updateButtonText()
  }

  updateButtonText() {
    if (this.hasButtonTarget) {
      this.buttonTarget.textContent = this.contentTarget.classList.contains('hidden')
        ? this.openTextValue
        : this.closedTextValue
    }
  }
}
