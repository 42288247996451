import { Controller, add } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['wordCount', 'target']
  static values = { minWord: Number, maxWord: Number, wordCount: Number, text: String }

  connect() {
    this.handleUpdate(this.wordCountValue)
  }

  textValueChanged(text) {
    const wordCount = (text.match(/(\p{Letter}|\d)+/gu) || []).length
    this.handleUpdate(wordCount)
  }

  // private

  handleUpdate(wordCount) {
    this.wordCountTarget.innerText = wordCount

    this.progessBarElement.style.width = `${
      (wordCount / Math.max(this.minWordValue, this.maxWordValue)) * 100
    }%`

    if (
      (this.minWordValue <= 1 || wordCount >= this.minWordValue) &&
      (this.maxWordValue < 1 || wordCount <= this.maxWordValue)
    ) {
      this.progessBarElement.classList.add('bg-green')
      this.progessBarElement.classList.remove('bg-secondary')
    } else {
      this.progessBarElement.classList.add('bg-secondary')
      this.progessBarElement.classList.remove('bg-green')
    }
  }

  get progessBarElement() {
    return this.wordCountTarget.parentNode
  }
}
