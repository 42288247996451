import { Controller } from '@hotwired/stimulus'

// This controller is used to autofocus on an element
// the html attribute is not enough when the content is morphed from turbo
export default class extends Controller {
  connect() {
    requestAnimationFrame(() => {
      this.element.focus()
    })
  }

  focus(e) {
    e.currentTarget.focus()
  }
}
