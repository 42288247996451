import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['textarea', 'mutable']

  connect() {
    this.textareaTarget.addEventListener('keyup', e => this.toggleMutable(e))
  }

  disconnect() {
    this.textareaTarget.removeEventListener('keyup', e => this.toggleMutable(e))
  }

  toggleMutable(e) {
    JSON.parse(this.mutableTarget.dataset.mutables).forEach(mutable => {
      if (
        this.wordCount(e.currentTarget.value) >= this.mutableTarget.dataset.minimalMutableWordCount
      ) {
        if (mutable.mutable_type === 'add_class') {
          this.mutableTarget.classList.add(mutable.mutable)
        } else if (mutable.mutable_type === 'remove_class') {
          this.mutableTarget.classList.remove(mutable.mutable)
        } else if (mutable.mutable_type === 'add_attribute') {
          this.mutableTarget.setAttribute(mutable.mutable, mutable.mutable_value)
        } else if (mutable.mutable_type === 'remove_attribute') {
          this.mutableTarget.removeAttribute(mutable.mutable)
        }
      } else {
        if (mutable.mutable_type === 'add_class') {
          this.mutableTarget.classList.remove(mutable.mutable)
        } else if (mutable.mutable_type === 'remove_class') {
          this.mutableTarget.classList.add(mutable.mutable)
        } else if (mutable.mutable_type === 'add_attribute') {
          this.mutableTarget.removeAttribute(mutable.mutable)
        } else if (mutable.mutable_type === 'remove_attribute') {
          this.mutableTarget.setAttribute(mutable.mutable, mutable.mutable_value)
        }
      }
    })
  }

  wordCount(sentence) {
    return (sentence.match(/([A-zÀ-ú]+)/gu) || []).length
  }
}
