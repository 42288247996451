// common function for all games are inherited from game_controller
import Controller from './game_controller'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['letter']
  static values = { answer: String }

  initialize() {
    // we must define it again otherwise it is overwriten by Stimulus default Dispacth function
    useDispatch(this, { eventPrefix: false })
  }

  // actions
  validate = e => {
    e.preventDefault()
    e.stopPropagation()
    this.checkedTargets.forEach(wordTarget => {
      const isValidWord = wordTarget.dataset.isValidWord === 'true'
      wordTarget.classList.toggle('success', isValidWord)
      wordTarget.classList.toggle('error', !isValidWord)
    })
    if (this.isAnswerCorrect) {
      this.success()
    } else {
      this.error()
      this.#resetColors()
    }
  }

  #resetColors = () => {
    setTimeout(() => {
      this.wordTargets.forEach(el => {
        el.checked = false
        el.classList.toggle('error', false)
        el.classList.toggle('success', false)
      })
    }, 2000)
  }

  // getters
  get checkedTargets() {
    return this.wordTargets.filter(checkbox => checkbox.checked)
  }

  get isAnswerCorrect() {
    return (
      this.checkedTargets.every(checkbox => checkbox.dataset.isValidWord === 'true') &&
      this.checkedTargets.length > 0
    )
  }

  get selectedWords() {
    return this.checkedTargets.map(checkbox => checkbox.value)
  }
}
