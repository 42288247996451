import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { path: String }

  connect() {
    window.setTimeout(() => {
      this.updateStatus()
    }, 1000)
  }

  updateStatus() {
    fetch(this.path)
      .then(response => response.text())
      .then(html => {
        this.element.outerHTML = html
      })
      .catch(err => console.log(err))
  }

  get path() {
    return this.pathValue || this.data.get('path')
  }
}
