import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.getElementById(`${this.anchor}-tab`)?.click()
  }

  // private

  get anchor() {
    return document.URL.split('#').length > 1 ? document.URL.split('#')[1] : null
  }
}
