import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output', 'cancel_reason', 'other']

  toggleOther(e) {
    const display_cancel_reason = e.currentTarget.dataset.cancel_reason === 'other'

    this.otherTarget.classList.toggle('opacity-100', display_cancel_reason)
    this.otherTarget.classList.toggle('opacity-0', !display_cancel_reason)
  }
}
