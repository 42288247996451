import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class SlideIn extends Controller {
  static values = {
    delay: { type: Number, default: 3000 },
    hidden: { type: Boolean, default: false },
    persistent: { type: Boolean, default: false },
    appearDelay: { type: Number, default: 0 }
  }

  initialize() {
    this.hide = this.hide.bind(this)
    this.timeout = null
    this.enter = null
    this.leave = null
    this.transitioned = false
  }

  connect() {
    useTransition(this)

    if (this.hiddenValue === false) {
      setTimeout(() => {
        this.show()
      }, this.appearDelayValue)
    }
  }

  show() {
    if (typeof this.enter === 'function') {
      this.enter()
    }

    if (!this.persistentValue) {
      this.timeout = setTimeout(this.hide, this.delayValue)
    }
  }

  async hide() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (typeof this.leave === 'function') {
      await this.leave()
    }

    this.element.remove()
  }
}
