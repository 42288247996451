import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['submit']
  static debounces = ['submit']
  static values = {
    debounce: { type: Number, default: 300 },
    toggleBusy: { type: Boolean, default: false }
  }

  initialize() {
    useDebounce(this, { wait: this.debounceValue })
  }

  toggleSubmit(e) {
    this.submitTarget.disabled = !e.currentTarget.checked
    this.submitTarget.classList.toggle('disabled', !e.currentTarget.checked)
    if (e.currentTarget.checked) {
      this.submitTarget.removeAttribute('tooltip')
    } else {
      this.submitTarget.setAttribute(
        'tooltip',
        'Vous devez accepter les conditions générales de vente'
      )
    }
  }

  submit(e) {
    this.requestSubmit(e)
  }

  requestSubmit(e) {
    this.element.requestSubmit()
    this.#setSubmitTargetAsBusy(e)
  }

  visit(event) {
    if (event.detail.success) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }

  #setSubmitTargetAsBusy(e) {
    const submitTarget = e.currentTarget || this.submitTarget
    if (!submitTarget) return
    if (!this.toggleBusyValue) return

    this.submitTarget.setAttribute('busy', 'true')
  }

  get submitTarget() {
    return this.element.querySelector('input[type="submit"], button[type="submit"]')
  }
}
