import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['text', 'showMoreBtn', 'showLessBtn']

  showMore(e) {
    e.preventDefault()
    this.textTarget.classList.remove('hidden')
    this.showMoreBtnTarget.classList.add('hidden')
    this.showLessBtnTarget.classList.remove('hidden')
  }

  showLess(e) {
    e.preventDefault()
    this.textTarget.classList.add('hidden')
    this.showMoreBtnTarget.classList.remove('hidden')
    this.showLessBtnTarget.classList.add('hidden')
  }
}
