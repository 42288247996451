import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu', 'toggler']

  toggle(e) {
    this.isOpen = !this.isOpen
    this.render()
  }

  toggleBackground() {
    this.element.classList.toggle(
      'bg-light-white-shadow',
      window.pageYOffset > 10 + this.toggleOffsetValue
    )
  }

  toggleFixedTop() {
    this.element.classList.toggle('fixed-top', window.pageYOffset > 10 + this.toggleOffsetValue)
  }

  render() {
    if (this.isOpen) {
      $(this.menuTarget).collapse('show')
    } else {
      $(this.menuTarget).collapse('hide')
    }

    this.togglerTarget.classList.toggle('fa-bars', !this.isOpen)
    this.togglerTarget.classList.toggle('fa-times', this.isOpen)
    this.togglerTarget.classList.toggle('open', this.isOpen)
    // document.body.style.overflow = this.isOpen ? "hidden" : "unset";
  }

  get isOpen() {
    return this.data.get('open') === 'true'
  }

  get toggleOffsetValue() {
    return this.data.has('toggleOffset') ? parseInt(this.data.get('toggleOffset')) : 0
  }

  set isOpen(value) {
    this.data.set('open', value)
  }
}
