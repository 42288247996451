import Controller from './application_controller'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  initialize() {
    useDispatch(this, { eventPrefix: false })
  }

  connect() {
    this.logoutIfNeeded()
    this.useLinkInterceptor()
  }

  disconnect() {
    this.removeLinkInterceptor()
  }

  //actions

  sendMessage(e) {
    const message = e.currentTarget.dataset.message
    if (window.ReactNativeWebView) {
      e.preventDefault()
      this.sendMessageToApp({ type: message })
    }
  }

  handleMobilePurchase(e) {
    // Here we send a message to RNWV
    const slug = e.currentTarget.dataset.slug
    const iosSlug = e.currentTarget.dataset.iosSlug || slug

    if (this.inAppPurchaseMeta && window.ReactNativeWebView) {
      e.preventDefault()
      e.stopImmediatePropagation()
      this.sendMessageToApp({ type: 'subscription_purchase', body: { slug, iosSlug } })
    } else {
      console.log({ type: 'subscription_purchase', body: { slug, iosSlug } })
    }
  }

  // callbacks

  linkClicked = event => {
    if (event.target.getAttribute('target') === '_blank') {
      event.preventDefault()
      this.sendMessageToApp({
        type: 'navigate',
        body: { page: 'Page', options: { uri: event.target.href } }
      })
    }
  }

  // private

  logoutIfNeeded() {
    // in case we visit a page on we are not signed on the webview we log out the user from the App
    if (!this.userId) {
      this.sendMessageToApp({ type: 'logout' })
    }
  }

  sendMessageToApp(payload) {
    const message = JSON.stringify(payload)
    window.ReactNativeWebView?.postMessage(message)
  }

  useLinkInterceptor() {
    this.element.addEventListener('click', this.linkClicked, false)
  }

  removeLinkInterceptor() {
    this.element.removeEventListener('click', this.linkClicked)
  }

  // legacy for 1.2.1 version
  process(event) {
    const { type, body } = event.detail
    this.sendMessageToApp({ type: 'received', body })

    switch (type) {
      case 'receipt':
        this.sendMessageToApp({ type: 'subscription_start', body })
        this.createSubscription(body)
        break
      case 'inAppInitialized':
        this.break
      default:
        console.log('Message cannot be processed')
    }
  }
}
