export class EditableZone {
  constructor(controller) {
    this.controller = controller
  }

  addBefore(element) {
    if (
      !element.previousElementSibling ||
      !element.previousElementSibling.classList.contains('editable')
    ) {
      element.before(this.template)
    }
  }

  addAfter(element) {
    if (!element.nextElementSibling || !element.nextElementSibling.classList.contains('editable')) {
      element.after(this.template)
    }
  }

  initZones() {
    const editables = this.controller.sentenceTarget.querySelectorAll('.editable')
    const composedWords = this.controller.sentenceTarget.querySelectorAll('.composed-word')

    editables.forEach(editable => editable.remove())

    if (composedWords.length > 0) {
      composedWords.forEach(word => this.buildZones(word))
    } else {
      this.controller.sentenceTarget.appendChild(this.template)
    }
  }

  buildZones(element) {
    const { previousElementSibling, nextElementSibling } = element
    const shouldInsertBefore =
      !previousElementSibling || previousElementSibling.classList.contains('composed-word')

    shouldInsertBefore && this.controller.sentenceTarget.insertBefore(this.template, element)

    !nextElementSibling && element.after(this.template)
  }

  setEndOfContenteditable(element) {
    let range, selection

    if (document.createRange) {
      range = document.createRange()
      range.selectNodeContents(element)
      range.collapse(false)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    } else if (document.selection) {
      range = document.body.createTextRange()
      range.moveToElementText(element)
      range.collapse(false)
      range.select()
    }
  }

  splitEditableZone(element, cursorPosition) {
    const editableZoneText = element.textContent

    const firstPart = editableZoneText.slice(0, cursorPosition)
    const secondPart = editableZoneText.slice(cursorPosition)

    element.textContent = firstPart
    element.after(this.template)
    element.nextElementSibling.textContent = secondPart
    element.nextElementSibling.classList.add(...this.controller.editableClasses)
    element.nextElementSibling.classList.remove('editable')
    element.nextElementSibling.focus()

    return element.nextElementSibling
  }

  addNewEditableZoneAfter(element) {
    if (element.textContent.length > 0) {
      !element.nextElementSibling && element.after(this.template)

      if (element.previousElementSibling && element.previousElementSibling.textContent.length > 0) {
        element.before(this.template)
      }
      const newEditableZone = element.nextElementSibling
      newEditableZone.focus()
      newEditableZone.classList.add(...this.controller.editableClasses)
      newEditableZone.before(this.template)
      return newEditableZone
    } else {
      element.after(this.lineBreakTemplate)
      element.classList.remove(...this.controller.editableClasses)
      let newStart = element.nextElementSibling
      newStart.after(this.template)
      let nextContentEditable = newStart.nextElementSibling
      nextContentEditable.focus()
      return nextContentEditable
    }
  }

  // Getters
  get template() {
    return (
      this.controller.textZoneTemplateTarget &&
      this.controller.textZoneTemplateTarget.content.cloneNode(true)
    )
  }

  get lineBreakTemplate() {
    return (
      this.controller.lineBreakTemplateTarget &&
      this.controller.lineBreakTemplateTarget.content.cloneNode(true)
    )
  }
}
