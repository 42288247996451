import { Controller } from '@hotwired/stimulus'
import { useClickOutside, useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ['menu', 'toggleButton', 'openIcon', 'closeIcon']

  initialize() {
    useClickOutside(this)

    useTransition(this, {
      element: this.menuTarget,
      enterActive: 'transition ease-out duration-200',
      enterFrom: 'transform opacity-0 scale-95',
      enterTo: 'transform opacity-100 scale-100',
      leaveActive: 'transition ease-in duration-75',
      leaveFrom: 'transform opacity-100 scale-100',
      leaveTo: 'transform opacity-0 scale-95',
      hiddenClass: 'hidden'
    })
  }

  disconnect() {
    this.#close()
  }

  // actions
  toggle() {
    this.#render()
  }

  // callbacks
  clickOutside(event) {
    this.#close()
  }

  // private

  #render() {
    // menu items
    if (this.element.open) {
      this.enter()
      this.element.setAttribute('data-turbo-permanent', true)
    } else {
      this.leave()
      this.element.removeAttribute('data-turbo-permanent')
    }

    // toggle icons
    if (this.hasOpenIconTarget) {
      this.openIconTarget.classList.toggle('hidden', this.element.open)
    }
    if (this.hasCloseIconTarget) {
      this.closeIconTarget.classList.toggle('hidden', !this.element.open)
    }
    // aria attributes
    this.toggleButtonTarget.setAttribute('aria-expanded', this.element.open)
  }

  #close(event) {
    this.element.open = false
  }
}
