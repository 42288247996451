import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = ['tooltipContent']
  static values = {
    placement: String,
    theme: String,
    maxWidth: String,
    disabledContent: String,
    delay: { type: Number, default: 0 }
  }

  // Lifecycle
  connect() {
    import(/* webpackChunkName: "tippy.js" */ 'tippy.js').then(Module => {
      this.TippyConstructor = Module.default
      this.tippy = new this.TippyConstructor(this.element, { ...this.tippyOptions })
      this.#setTooltipContent()
      useMutation(this, { attributes: true, attributeFilter: ['disabled'], subtree: true })
    })
  }

  disconnect() {
    this.tippy?.destroy()
  }

  // actions

  disable() {
    this.tippy?.disable()
  }

  enable() {
    this.tippy?.enable()
  }

  // callback

  mutate(entries) {
    for (const mutation of entries) {
      // Change the text of the tooltip for a disabled button
      this.#changeTooltipContent(mutation)
    }
  }

  // private

  #setTooltipContent() {
    // Set the text of the tooltip for checking if it is disabled or not
    for (const child of this.element.childNodes) {
      if (child.classList !== undefined) {
        if (child.hasAttribute('disabled') && this.disabledContentValue !== '') {
          this.tippy?.setContent(this.disabledContentValue)
        } else {
          this.tippy?.setContent(this.tooltipContentTarget.innerHTML)
        }
      }
    }
  }

  #changeTooltipContent(mutation) {
    if (this.disabledContentValue !== '' && this.element !== mutation.target) {
      if (mutation.target.disabled) {
        this.tippy?.setContent(this.disabledContentValue)
      } else {
        this.tippy?.setContent(this.tooltipContentTarget.innerHTML)
      }
    }
  }

  // Getters and setters

  get tippyOptions() {
    return {
      allowHTML: true,
      touch: ['hold', 500],
      placement: this.placementValue,
      theme: this.themeValue,
      maxWidth: this.maxWidthValue,
      delay: this.delayValue
    }
  }
}
