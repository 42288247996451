import Controller from './game_controller'
import { useDispatch } from 'stimulus-use'
import { Sortable } from 'sortablejs'

export default class extends Controller {
  static targets = ['sentence', 'sentences']
  static values = { correctOrder: String }

  initialize() {
    useDispatch(this, { eventPrefix: false })
  }

  connect() {
    if (this.hasSentencesTarget) {
      this.sortable = Sortable.create(this.sentencesTarget, {
        animation: 300,
        easing: 'cubic-bezier(1, 0, 0, 1)',
        dragoverBubble: true,
        onEnd: this.play,
        dataIdAttr: 'data-position',
        dragClass: 'dragged'
      })
    }
  }

  // actions

  moveUp(event) {
    const { position } = event.currentTarget.dataset
    const order = this.sortable.toArray()
    const index = order.indexOf(position)
    order.splice(index, 1)
    order.splice(index - 1, 0, position)
    this.sortable.sort(order) // apply
    this.play()
  }

  moveDown(e) {
    const { position } = e.currentTarget.dataset
    const order = this.sortable.toArray()
    const index = order.indexOf(position)
    order.splice(index, 1)
    order.splice(index + 1, 0, position)
    this.sortable.sort(order) // apply
    this.play()
  }

  validate = e => {
    e.preventDefault()
    this.sentenceTargets.forEach((sentence, index) => {
      if (sentence.dataset.position == index) {
        sentence.dataset.correctAnswer = true
      } else {
        sentence.dataset.correctAnswer = false
      }
    })
    setTimeout(() => {
      this.sentenceTargets.forEach(sentence => {
        sentence.dataset.correctAnswer = null
      })
    }, 1000)
    if (this.isAnswerCorrect()) {
      this.success()
    } else {
      this.error()
    }
  }

  isAnswerCorrect(e) {
    return (
      JSON.stringify(this.sentencesPositions) === JSON.stringify(this.sentencesPositions.sort())
    )
  }

  //getters

  get sentencesPositions() {
    return this.sentenceTargets.map(sentence => parseInt(sentence.dataset.position))
  }
}
