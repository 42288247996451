import { Controller } from '@hotwired/stimulus'

const sleep = async duration => {
  await new Promise(f => setTimeout(f, duration))
}

export default class extends Controller {
  static targets = ['wordCount']
  static values = { scrollIntoView: Boolean }

  async connect() {
    if (this.scrollIntoViewValue) {
      this.element.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  }

  async onStream(event) {
    const completedWriting = event.target.firstChild.content.querySelector(
      '.writing--state--completed'
    )
    if (!completedWriting) return

    event.preventDefault()
    await this.animateCompletion(completedWriting)
    event.target.performAction()
  }

  async animateCompletion(completedWriting) {
    completedWriting.classList.add('animation-request')
    this.element.classList.add('hide-fade-out')
    await sleep(1000)
  }

  writingUpdated(event) {
    if (!this.hasWordCountTarget) return

    const content = event.target.value
    const wordCount = (content.match(/(\p{Letter}|\d)+/gu) || []).length

    this.dispatch('updated', {
      detail: { wordCount: wordCount },
      target: this.wordCountTarget
    })
  }
}
