import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { element: String }

  connect() {
    if (this.elementValue) {
      let element = document.querySelector(this.elementValue)

      if (!element) return

      if (element.style.display === 'none') {
        element = element.parentElement
      }
      element.classList.add('relative')
      element.appendChild(this.createChildElement())
    }
  }

  createChildElement() {
    const span = document.createElement('span')
    span.classList.add(
      ...[
        'absolute',
        'animate-ping',
        'h-3',
        'w-3',
        'rounded-full',
        'bg-blue',
        'opacity-75',
        'top-0',
        '-right-2'
      ]
    )

    return span
  }
}
