import { getMetaValue } from '../utils'

export const shouldEnableAxeptio = getMetaValue('axeptio')

export function resetConsentBeforeDate(axeptio, date) {
  try {
    const json = axeptio.userPreferencesManager.readChoicesFromCookies('axeptio_cookies')
    if (json && json.$$date) {
      const acceptedAt = new Date(json.$$date)
      if (date.getTime() - acceptedAt.getTime() > 0) {
        window.openAxeptioCookies()
      }
    }
  } catch (err) {
    console.error('Axeptio could not read previous choices nor parse consent date', err)
  }
}

export function enableGoogleAnalytics() {
  const shouldEnableGoogleAnalytics = getMetaValue('google_analytics')
  if (!shouldEnableGoogleAnalytics) return

  const google_analytics_id = getMetaValue('google_analytics_id')

  var el = document.createElement('script')
  el.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${google_analytics_id}`)
  el.setAttribute('async', true)

  if (document.head !== null) {
    document.head.appendChild(el)
  }

  gtag('js', new Date())
  gtag('config', google_analytics_id, {
    page_title: document.title,
    page_path: location.href.replace(location.origin, '')
  })

  // Turbo version
  document.addEventListener('turbo:load', () => {
    // Google Analytics.
    // https://github.com/turbolinks/turbolinks/issues/73#issuecomment-460028854
    if (typeof gtag == 'function') {
      gtag('config', google_analytics_id, {
        page_title: document.title,
        page_path: location.href.replace(location.origin, '')
      })
    }
  })
}

export function enableHotjar() {
  const shouldEnableHotjar = getMetaValue('hotjar')
  if (!shouldEnableHotjar) return

  function hotjar(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: 2053389, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  }

  // activate
  hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

export function enableFacebookPixel() {
  const shouldEnableFacebookPixel = getMetaValue('facebook_pixel')
  if (!shouldEnableFacebookPixel) return

  function pixel(f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  }
  // add the tag element
  pixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

  // activate
  const facebookPixelId = getMetaValue('facebook_pixel_id')
  fbq('init', facebookPixelId)
  fbq('track', 'PageView')
}

export function enableWisepops() {
  const shouldWisepops = getMetaValue('wisepops')
  if (!shouldWisepops) return
  console.log('wp')

  function wisepops(W, i, s, e, P, o, p) {
    W['WisePopsObject'] = P
    ;(W[P] =
      W[P] ||
      function () {
        ;(W[P].q = W[P].q || []).push(arguments)
      }),
      (W[P].l = 1 * new Date())
    ;(o = i.createElement(s)), (p = i.getElementsByTagName(s)[0])
    o.defer = 1
    o.src = e
    p.parentNode.insertBefore(o, p)
  }

  //activate
  const wisepopsId = getMetaValue('wisepops_id')

  wisepops(
    window,
    document,
    'script',
    `//loader.wisepops.com/get-loader.js?v=1&site=${wisepopsId}`,
    'wisepops'
  )
}
