export default class StoryCache {
  /////////////////////////////////////////////
  // Constructor && Public Accessors         //
  /////////////////////////////////////////////

  #field
  #cacheID
  #handleError

  constructor(field, cacheID, handleError) {
    this.#field = field
    this.#cacheID = cacheID
    this.#handleError = handleError
  }

  /////////////////////////////////////////////
  // Public Methods                          //
  /////////////////////////////////////////////

  save() {
    try {
      this.#save()
    } catch (error) {
      this.#handleError(error)
    }

    return this
  }

  load() {
    try {
      this.#load()
    } catch (error) {
      this.#handleError(error)
    }
  }

  reset() {
    try {
      this.#reset()
    } catch (error) {
      this.#handleError(error)
    }
  }

  /////////////////////////////////////////////
  // Private Methods and Getters             //
  /////////////////////////////////////////////

  #save() {
    const story = {
      updatedAt: Date.now(),
      content: this.#field.value
    }

    localStorage.setItem(
      'plume_stories',
      JSON.stringify({ ...StoryCache.#cachedStories, [this.#cacheID]: story })
    )
  }

  #load() {
    const { content, updatedAt } = StoryCache.#cachedStories[this.#cacheID] || {}
    if (!content || updatedAt <= this.updatedAtValue) return this

    this.#field.value = content
  }

  #reset() {
    const storage = StoryCache.#cachedStories
    delete storage[this.#cacheID]

    localStorage.setItem('plume_stories', JSON.stringify(storage))
  }

  /////////////////////////////////////////////
  // Static Methods                          //
  /////////////////////////////////////////////

  static get #cachedStories() {
    try {
      return JSON.parse(localStorage.getItem('plume_stories')) || {}
    } catch (err) {
      return {}
    }
  }
}
