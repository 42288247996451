import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'submit']
  static value = { dispalyErrors: { type: Boolean, default: true } }

  connect() {
    this.form.setAttribute('novalidate', true)
    this.form.addEventListener('blur', this.onBlur, true)
    this.form.addEventListener('keydown', this.onEnter, true)
    this.form.addEventListener('change', this.onChange, true)
    this.fieldTargets.forEach(field => {
      field.addEventListener('blur', () => this._touched(field.id))
    })
    this.submitButton && (this.submitButton.disabled = true)
    this.touched = {}
    this.validateForm()
  }

  disconnect() {
    this.form.removeAttribute('novalidate')
    this.form.removeEventListener('keydown', this.onEnter)
    this.form.removeEventListener('blur', this.onBlur)
    this.form.removeEventListener('change', this.onChange)
    this.form.removeEventListener('submit', this.onSubmit)
    this.fieldTargets.forEach(field => {
      field.removeEventListener('blur', () => this._touched(field.id))
    })
    this.submitButton && (this.submitButton.disabled = false)
  }

  onBlur = event => {
    this.validateField(event.target)
    this.validateForm()
  }

  onEnter = event => {
    this.validateForm()
    if (event.key === 'Enter') {
      this.onBlur(event)
    }
  }

  onChange = event => {
    this.validateForm()
  }

  _touched(fieldId) {
    this.touched[fieldId] = true
  }

  validateForm() {
    requestAnimationFrame(() => {
      let isValid = true
      // Not using `find` because we want to validate all the fields
      this.formFields.forEach(field => {
        if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
      })

      this.submitButton && (this.submitButton.disabled = !isValid)

      if (this.submitButton && this.submitButton.dataset.shouldBeDisable != undefined) {
        this.submitButton.disabled = this.submitButton.dataset.shouldBeDisable != 'false'
      }

      return isValid
    })
  }

  validateField(field) {
    if (!this.shouldValidateField(field)) return true
    const isValid = field.checkValidity()
    field.classList.toggle('invalid', !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField(field) {
    return (
      !field.disabled &&
      !['file', 'reset', 'submit', 'button'].includes(field.type) &&
      typeof field.checkValidity === 'function'
    )
  }

  refreshErrorForInvalidField(field, isValid) {
    if (!this.dispalyErrorsValue) return

    this.removeExistingErrorMessage(field)
    if (!isValid && this.touched[field.id]) this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage(field) {
    const existingErrorMessageElement = document.getElementById(`${field.id}_error`)
    existingErrorMessageElement && existingErrorMessageElement.remove()
  }

  showErrorForInvalidField(field) {
    const errorContainer = document.getElementById(`${field.id}_error_container`)
    if (!!errorContainer) {
      errorContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
    } else {
      field.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    }
  }

  buildFieldErrorHtml(field) {
    return `
      <p class="help-block text-orange text-right text-small font-weight-light" id="${field.id}_error">
        ${field.validationMessage}
      </p>
    `
  }

  get form() {
    return this.hasFormTarget ? this.formTarget : this.element || this.element.closest('form')
  }

  get submitButton() {
    return this.hasSubmitTarget
      ? this.submitTarget
      : this.form.querySelector('input[type=submit], button[type=submit]')
  }

  get formFields() {
    return Array.from(this.form.elements)
  }

  get fieldTargets() {
    return Array.from(this.form.querySelectorAll('input, select, textarea'))
  }

  get firstInvalidField() {
    return this.formFields.find(field => !field.checkValidity())
  }

  get submit() {
    return
  }
}
