import Controller from './application_controller'
import { useFieldAutoResize } from '../mixin/useFieldAutoResize'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useIntersection(this)

    useFieldAutoResize(this, {
      scrollRootElement: document.querySelector('[data-scroll-container]')
    })
  }

  appear() {
    this.computeAndApplyResize()
  }
}
