import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'pane']
  static values = {
    activeIndex: Number,
    setAnchor: Boolean,
    progressiveMode: {
      type: Boolean,
      default: false
    }
  }
  static classes = ['active', 'hidden', 'bg']
  progressIndex = 0
  connected = false

  connect() {
    this.connected = true
    this.activeIndexValue = this.anchorIndex || this.tabParamIndex
  }

  show(e) {
    const indexId = e.params.id
    const index = this.tabIds.indexOf(indexId)

    if (this.progressiveModeValue && index > this.progressIndex) return

    if (index !== -1) {
      this.activeIndexValue = index
    }

    const { bgColorClass } = e.params
    this.updateBackgroundColor(bgColorClass)
    if (this.setAnchorValue == true) {
      this.setAnchor()
    }
  }

  next(e) {
    if (this.activeIndexValue < this.tabTargets.length - 1) {
      this.activeIndexValue++
      this.progressIndex++
    }
  }

  // callbacks
  activeIndexValueChanged(activeIndex) {
    // we don't want to set the active index during the first call back
    // This call back occurs between the initialze and connect callbacks
    // to preent a blink if the anchor is set we wait for the controller to be fullly connected
    if (!this.connected) return

    this.paneTargets.forEach((pane, index) => {
      pane.classList.toggle(this.hiddenClassWithDefault, !(index == activeIndex))
    })

    if (this.progressiveModeValue) {
      this.tabTargets[activeIndex].classList.add(this.activeClassWithDefault)
      this.tabTargets.forEach((tab, index) => {
        tab.disabled = index > this.progressIndex
      })
    } else {
      this.tabTargets.forEach((tab, index) => {
        // set aria selected for accessibility
        tab.setAttribute('aria-selected', index == activeIndex)
        tab.classList.toggle(this.activeClassWithDefault, index == activeIndex)

        if (index == activeIndex) {
          tab.focus({ preventScroll: true })
        }
      })
    }
  }

  // private

  updateBackgroundColor(bgColorClass) {
    if (!bgColorClass) return

    this.element.classList.remove(...this.bgClasses)
    this.element.classList.add(bgColorClass)
  }

  // setters

  setAnchor() {
    history.pushState(null, null, `#${this.tabIds[this.activeIndexValue]}`)
  }

  // getters

  get tabIds() {
    return this.tabTargets.map(tab => tab.dataset.tabsIdParam)
  }

  get anchor() {
    return new URL(window.location.href).hash?.replace('#', '')
  }

  get anchorIndex() {
    const index = this.tabIds.indexOf(this.anchor)
    return index === -1 ? 0 : index
  }

  get tabParamIndex() {
    // extract from url tab=index1,index2
    // iterate over the tab ids and return the first index that matches
    const tabParam = new URL(window.location.href).searchParams.get('tab')
    if (!tabParam) return 0

    const tabIds = tabParam.split(',')
    const index = this.tabIds.findIndex(tabId => tabIds.includes(tabId))
    return index === -1 ? 0 : index
  }

  get activeClassWithDefault() {
    return this.hasActiveClass ? this.activeClass : 'active'
  }

  get hiddenClassWithDefault() {
    return this.hasHiddenClass ? this.hiddenClass : 'hidden'
  }
}
