const defaultOptions = {
  events: ['keyup'],
  warningCharacterCount: 120,
  maxCharacterCount: 140,
  textWarningClass: 'text-yellow',
  textOverflowClass: 'text-red',
  textDefaultClass: 'text-dark'
}

export const useFieldCharacterCount = (controller, options = {}) => {
  const {
    events,
    maxCharacterCount,
    textDefaultClass,
    textOverflowClass,
    textWarningClass,
    warningCharacterCount
  } = {
    ...defaultOptions,
    ...options
  }

  const fieldElement =
    options.fieldElement ||
    (controller.hasFieldTarget && controller.fieldTarget) ||
    controller.element
  const viewElement = options.viewElement || (controller.hasViewTarget && controller.viewTarget)

  const onEvent = () => {
    controller.characterCount = fieldElement.value.length
    controller.displayMessage()
  }

  const controllerDisconnect = controller.disconnect
  const controllerObserveCharacterCount = controller._observeCharacterCount
  const controllerUnObserveCharacterCount = controller._unObserveCharacterCount
  const controllerDisplayMessage = controller.displayMessage

  Object.assign(controller, {
    _observeCharacterCount() {
      events.forEach(event => {
        fieldElement.addEventListener(event, onEvent)
      })
      controllerObserveCharacterCount && controllerObserveCharacterCount()
      controller.characterCount = fieldElement.value.length
      controller.displayMessage()
    },
    _unObserveCharacterCount() {
      events.forEach(event => {
        fieldElement.removeEventListener(event, onEvent)
      })
      controllerUnObserveCharacterCount && controllerUnObserveCharacterCount()
    },
    displayMessage() {
      if (viewElement) {
        viewElement.textContent = `${controller.characterCount}/${maxCharacterCount}`

        // apply the different class to notifiy the warning and overflow
        viewElement.classList.toggle(
          textOverflowClass,
          controller.characterCount > maxCharacterCount
        )
        viewElement.classList.toggle(
          textWarningClass,
          controller.characterCount > warningCharacterCount &&
            controller.characterCount <= maxCharacterCount
        )
        viewElement.classList.toggle(
          textDefaultClass,
          controller.characterCount <= warningCharacterCount
        )
      }
      controllerDisplayMessage && controllerDisplayMessage()
    },
    disconnect() {
      this._unObserveCharacterCount()
      controllerDisconnect()
    }
  })
  controller._observeCharacterCount()
}
