import { Controller } from '@hotwired/stimulus'
import copy from 'copy-to-clipboard'

export default class extends Controller {
  static targets = ['value', 'notification', 'copy']
  static classes = ['copied']

  // actions
  execute(e) {
    try {
      copy(this.content)
      this.copiedMessageAnimation(e.target)
    } catch (oo) {
      console.log(oo)
    }
  }

  // private

  copiedMessageAnimation() {
    this.notificationTarget.classList.add(...this.copiedClasses)
    this.copyTarget.classList.add('hidden')
    setTimeout(() => {
      this.notificationTarget.classList.remove(...this.copiedClasses)
      this.copyTarget.classList.remove('hidden')
    }, 1000)
  }

  // getters

  get content() {
    return this.valueTarget.value || this.valueTarget.textContent
  }
}
