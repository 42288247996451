import { getMetaValue } from './utils'
import {
  enableGoogleAnalytics,
  enableFacebookPixel,
  enableHotjar,
  enableWisepops,
  shouldEnableAxeptio
} from './vendor/axeptio'
import { enableSentry } from './vendor/sentry'

if (shouldEnableAxeptio) {
  const t = document.getElementsByTagName('script')[0]
  const e = document.createElement('script')
  e.async = true
  e.src = '//static.axept.io/sdk-slim.js'
  t.parentNode.insertBefore(e, t)

  void 0 === window._axcb && (window._axcb = [])
  window._axcb.push(function (axeptio) {
    const cookiesListUpdatedAt = new Date(getMetaValue('cookies_list_updated_at'))
    // resetConsentBeforeDate(axeptio, cookiesListUpdatedAt)
    axeptio.on('cookies:complete', function (choices) {
      if (choices.google_analytics) {
        enableGoogleAnalytics()
      }

      if (choices.facebook_pixel) {
        enableFacebookPixel()
      }

      if (choices.hotjar) {
        enableHotjar()
      }

      if (choices.Sentry) {
        enableSentry()
      }

      if (choices.Wisepop) {
        enableWisepops()
      }
    })
  })
}
