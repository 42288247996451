import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['text']
  static values = { text: String }

  connect() {
    useClickOutside(this)
    this.textTarget.innerHTML = this.textValue.replace('\n', '<br>')
  }

  toggle() {
    this.element.classList.toggle('expanded')
  }

  openWithText(event) {
    const text = event.detail.text

    this.textTarget.innerHTML = text?.replace('\n', '<br>')

    this.element.classList.remove('hidden')
    requestAnimationFrame(() => {
      this.element.classList.add('expanded')
      this.sibilings.forEach(sibiling => {
        sibiling.element.classList.remove('expanded')
        sibiling.element.classList.add('hidden')
      })
    })
  }

  close(e) {
    this.element.classList.remove('expanded')
  }

  clickOutside() {
    this.close()
  }

  get sibilings() {
    return this.application.controllers.filter(
      controller => controller.identifier == this.identifier && controller != this
    )
  }
}
