import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openCustomizationBar() {
    const customizationBar = document.getElementById('customization-bar')
    if (!customizationBar) return

    customizationBar.controller?.open(this)
    this.select()
  }

  select() {
    this.element.classList.add(
      'border-x-0',
      'border-t-0',
      'border-b-2',
      'border-b-blue',
      'border-solid'
    )
  }

  unselect() {
    this.element.classList.remove(
      'border-x-0',
      'border-t-0',
      'border-b-2',
      'border-b-blue',
      'border-solid'
    )
  }
}
