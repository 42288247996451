import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output', 'grade']

  connect() {
    this.toggleRequired()
  }

  select(e) {
    if (this.mode === 'single') {
      const grade = e.currentTarget.dataset.grade
      this.outputTarget.value = grade
      this.toggleButtonSingle(grade)
      this.toggleRequired()
    } else {
      this.toggleButtonMultiple(e.currentTarget, e.currentTarget.checked)
      this.updateOutputMultiple()
      this.toggleRequired()
    }
  }

  toggleButtonMultiple(el, checked) {
    el.closest('label').classList.toggle('active', checked)
  }

  toggleButtonSingle(grade) {
    this.gradeTargets.forEach(target => {
      const checked = grade === target.dataset.grade
      target.closest('label').classList.toggle('active', checked)
    })
  }

  updateOutputMultiple() {
    const grades = this.gradeTargets.reduce((acc, grade) => {
      if (grade.checked === true) {
        acc.push(grade.dataset.grade)
        this.setRequiredToFalse()
      }
      return acc
    }, [])
    this.outputTarget.value = grades.join(',')
  }

  toggleRequired() {
    const grades = this.gradeTargets.reduce((acc, grade) => {
      if (grade.checked === true) {
        acc.push(grade.dataset.grade)
      }
      return acc
    }, [])
    if (grades.length < 1) {
      this.setRequiredToTrue()
    } else {
      this.setRequiredToFalse()
    }
  }

  setRequiredToFalse() {
    this.gradeTargets.forEach(target => {
      target.required = false
    })
  }

  setRequiredToTrue() {
    this.gradeTargets.forEach(target => {
      target.required = true
    })
  }

  get mode() {
    return this.data.has('mode') ? this.data.get('mode') : 'multiple'
  }
}
