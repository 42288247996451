import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  // actions
  async print() {
    await this.#printElement(this.#printableElement)
    this.printSection.innerHTML = ''
  }

  async save() {
    // dynamic import to prevent loading this heavy package
    try {
      import('jspdf').then(async module => {
        const doc = new module.jsPDF('p', 'pt', [2100, 2970]) // eslint-disable-line new-cap
        this.#printableElement.style.width = '1200px'

        doc.html(this.#printableElement, {
          html2canvas: {
            scale: (1700 / 1200) * 1
          },
          callback: doc => {
            // window.open(doc.output('bloburl')) // for debugging
            doc.save('rapport-d-évaluation-plume.pdf')
            this.#printableElement.style.width = 'unset'
          },
          autoPaging: 'text',
          margin: 200,
          windowWidth: 1200,
          x: 0,
          y: 0
        })
      })
    } catch (error) {
      console.log(error)
      this.#printableElement.style.width = 'unset'
    }
  }

  // Private
  async #printElement(elem) {
    const domClone = elem.cloneNode(true)

    this.printSection.innerHTML = ''
    this.printSection.appendChild(domClone)

    return window.print()
  }

  // Getters
  get #printableElement() {
    return this.hasContentTarget ? this.contentTarget : this.element
  }

  get printSection() {
    return document.getElementById('print-section')
  }
}
