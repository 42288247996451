import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { event: String }

  initialize() {
    requestAnimationFrame(() => {
      if (typeof dataLayer === 'object') {
        dataLayer.push({ event: this.eventValue })
      }
    })
  }
}
